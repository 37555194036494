/***** LIFE INSURANCE PAGE STYLES *****/
@media only screen and (max-width: 1300px) {
  .life-insurance-page.page.container {
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 560px) {
  .life-insurance-page.page.container {
    padding: 0;
  }
}

/***** LIFE INSURANCE INFO CARD STYLES *****/
.life-insurance-sidebar-card {
  width: 256px;
  min-width: 256px;
  padding: 24px;
  margin-right: 24px;
  overflow: hidden;
}
.life-insurance-sidebar-card label {
  color: rgba(0, 0, 0, 0.48);
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
}
.life-insurance-sidebar-card .program-contact-info p {
  margin-bottom: 0;
}
.life-insurance-pricing-details {
  margin: 24px -24px;
  padding: 24px;
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  padding: 16px;
}

@media only screen and (max-width: 1040px) {
  .life-insurance-sidebar-card {
    width: 100%;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 560px) {
  .life-insurance-sidebar-card {
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    width: 100%;
    padding: 16px;
  }
}

/***** LIFE INSURANCE ENROLLMENT STYLES *****/
/*** Life Insurance Card Styles ***/
.life-insurance-sidebar-card + .full-width {
  max-width: calc(100% - 256px);
}
.life-insurance-form-section.terminated {
  opacity: 0.5;
}
.life-insurance-form-section:not(:last-child) {
  margin-bottom: 48px;
}
.life-insurance-card .section-title {
  font-size: 22px;
  border-bottom: 1px solid #edecec;
  padding-bottom: 16px;
  margin-bottom: 32px;
  height: auto;
  display: block;
}
.life-insurance-two-column-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
}
@media only screen and (max-width: 1176px) {
  .life-insurance-two-column-grid {
    grid-gap: 16px;
  }
}
@media only screen and (max-width: 1040px) {
  .life-insurance-sidebar-card + .full-width {
    max-width: 100%;
  }
  .life-insurance-card .section-title {
    padding: 16px 0;
  }
  .life-insurance-two-column-grid {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
}
@media only screen and (max-width: 560px) {
  .life-insurance-card {
    padding: 24px 16px;
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
  .life-insurance-card-header {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .life-insurance-card-header .tag.small {
    height: 24px;
    font-size: 13px;
    margin-bottom: 16px;
  }
  .life-insurance-card .xxl-text.fw-900 {
    font-size: 20px;
  }
  .life-insurance-card .section-title {
    margin: 0 -16px 24px;
    padding: 0 16px;
    font-size: 18px;
  }
}
/* Profile Styles */
.life-insurance-profile-inputs label.small-text.accent-text {
  width: 144px;
  min-width: 144px;
  display: inline-block;
}
.life-insurance-profile-inputs input {
  width: 100%;
}
.life-insurance-profile-inputs
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
/* Beneficiary Designation Styles */
.image-preview-modal {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-preview-modal:not(.image-preview-modal-error) > div {
  width: 96%;
  max-width: 1200px;
}
.image-preview-modal:not(.image-preview-modal-error) i {
  display: block;
  text-align: right;
}
.image-preview-modal-error i {
  font-size: 20px;
}
.image-preview-modal object {
  height: 80vh;
  width: 100%;
  max-width: 1200px;
}
.image-preview-modal .preview-message {
  height: 80vh;
  padding: 32px 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Payment Styles */
.life-insurance-payment-section
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
.payment-info-section label.small-text.accent-text {
  width: 144px;
  min-width: 144px;
}
@media only screen and (max-width: 560px) {
  .payment-info-section label.small-text.accent-text {
    margin-bottom: 4px;
    width: 100%;
    min-width: auto;
    display: block;
  }
}
/*** Life Insurance Form Button Styles ***/
.life-insurance-form-btns .btn {
  padding: 0 24px;
}
@media only screen and (max-width: 560px) {
  .life-insurance-form-btns {
    margin-bottom: 120px;
    padding: 0 16px;
    background: #fff;
  }
}
/*** Confirm Info Modal ***/
.confirm-info-details {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  padding: 24px 0;
}
.confirm-info-details p {
  margin-bottom: 8px;
}

/***** MY LIFE INSURANCE STYLES *****/
/*** Status Tag Styles ***/
.life-insurance-status-tag-PendingActivation {
  background: $accent-color;
  color: #fff;
}
.life-insurance-status-tag-Active {
  background: #63c674;
  color: #fff;
}
.life-insurance-status-tag-PendingTermination {
  background: #f3893d;
  color: #fff;
}
.life-insurance-status-tag-Terminated {
  background: rgba(0, 0, 0, 0.87);
  color: #fff;
}
.life-insurance-status-tag-FailedRenewal {
  background: $error-color;
  color: #fff;
}
/*** Profile Styles ***/
.life-insurance-profile-section {
  border-top: 1px solid #edecec;
  padding: 24px 0;
}
.life-insurance-profile-section:last-child {
  border-bottom: 1px solid #edecec;
}
.life-insurance-profile-section div p.fw-700 {
  width: 144px;
}
@media only screen and (max-width: 560px) {
  .life-insurance-profile-section {
    margin: 0 -16px;
    padding: 24px 16px;
  }
}

/***** PROGRAM ERROR PAGE STYLES *****/
.program-error-page {
  padding: 80px 16px;
}
.program-error-page img {
  max-width: 100%;
}

/***** PROGRAM SELECT STYLES *****/
.program-dropdown-select {
  min-width: 350px;
}
@media only screen and (max-width: 560px) {
  .program-dropdown-select {
    min-width: 100%;
    margin: 8px 0px 16px 0px;
  }
}

/***** PROGRAM FORM STYLES *****/
.program-form-section {
  border-top: 1px solid #edecec;
  margin-top: 24px;
  padding-top: 24px;
}
.program-form-section label.accent-text-dark {
  margin-right: 8px;
  width: 176px;
  min-width: 176px;
}
.program-form-section .custom-input.custom-message {
  margin-left: 40px;
  width: calc(100% - 40px);
}
.program-form-section .profile-pic-container img {
  border-radius: 3px;
}
.program-form-section-short-inputs .custom-input,
.program-form-section-short-inputs .custom-select {
  width: 280px;
}
.program-form-btns .error-message {
  bottom: -20px;
  right: 0;
}
@media only screen and (max-width: 560px) {
  .program-form-section-title {
    padding: 24px 16px 0;
    border-top: 1px solid #edecec;
  }
  .page-title.program-form-section-title {
    border-top: none;
  }
  .program-form-section {
    padding: 24px 16px 0;
  }
  .program-form-section label.accent-text-dark {
    margin-bottom: 4px;
    width: 100%;
  }
  .program-form-section label.accent-text-dark:not(.mobile-flex) {
    display: block;
  }
  .program-form-section-short-inputs .custom-input,
  .program-form-section-short-inputs .custom-select {
    width: 100%;
  }
  .program-form-btns {
    padding-top: 16px;
    margin-top: 8px;
  }
  .program-form-btns .error-message {
    bottom: 12px;
    width: 100%;
    text-align: center;
  }
}

/***** PROGRAM EMPTY STATE STYLES *****/
.program-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/***** PROGRAM HOME PAGE STYLES *****/
/*** Header Styles ***/
.program-home-header {
  margin-bottom: 32px;
}
@media only screen and (max-width: 560px) {
  .program-home-header {
    padding: 0 16px;
    margin-bottom: 16px;
  }
}
/* Select Styles */
.program-filter-select {
  border: none;
  font-size: 14px;
  font-family: AvenirHeavy;
  width: 100%;
}
.program-filter-select > .program-filter-select__control {
  border: none;
  background: transparent;
  cursor: pointer;
}
.program-filter-select__single-value {
  color: $accent-color !important;
}
.program-filter-select__indicator-separator {
  display: none;
}
.program-filter-select__dropdown-indicator {
  color: $accent-color !important;
}
@media only screen and (max-width: 560px) {
  .mobile-filters-dropdown {
    position: absolute;
    display: block;
    background: #fff;
    box-shadow:
      0 2px 5px -2px rgba(0, 0, 0, 0.12),
      0 2px 18px 2px rgba(0, 0, 0, 0.08);
    padding: 16px;
    border-radius: 3px;
    z-index: 1;
    right: 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms linear;
  }
  .mobile-filters-dropdown.open {
    opacity: 1;
    visibility: visible;
  }
}
/* Border Styles */
.program-home-header-border {
  position: absolute;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.04);
  border-bottom: none;
  margin-top: 32px;
}
@media only screen and (max-width: 560px) {
  .program-home-header-border {
    margin-top: 24px;
  }
}
/*** Grid List Styles ***/
.program-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  margin: 0 auto;
  width: 100%;
  justify-content: start;
}
.program-cards-grid > div {
  display: contents;
}
@media only screen and (max-width: 560px) {
  .program-list {
    padding: 0 16px 48px;
    justify-content: center;
  }
  .pagination.program-list-pagination {
    padding: 0 16px;
  }
  .program-cards-grid {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
/*** Card Styles ***/
.program-card.card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.program-card img {
  margin: -16px;
  width: calc(100% + 32px);
  height: 176px;
  object-fit: cover;
  padding-bottom: 32px;
  border-radius: 3px 3px 0 0;
}
.program-card-footer {
  border-top: 1px solid #edecec;
  margin: 16px -16px 0 -16px;
  padding: 16px 16px 0 16px;
}

/***** PROGRAM MANAGEMENT STYLES *****/
/*** Sidebar Styles ***/
.program-management-sidebar-card {
  width: 256px;
  padding: 24px;
}
.program-management-sidebar-card label {
  color: rgba(0, 0, 0, 0.48);
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
}
/* Navigation Styles */
.program-management-sidebar-menu-card li {
  border-left: 2px solid transparent;
  padding-left: 24px;
  margin-left: -24px;
  transition: all 300ms linear;
  outline: none;
}
.program-management-sidebar-menu-card li.active {
  border-left: 2px solid $accent-color;
  color: $accent-color;
}
@media only screen and (max-width: 1040px) {
  .program-management-sidebar-card {
    width: 100%;
  }
  .program-management-sidebar-menu-card {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: auto;
  }
  .program-management-sidebar-menu-card ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .program-management-sidebar-menu-card li {
    text-transform: none;
    font-family: AvenirRoman;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 0;
    border-left: none;
    margin: 0 16px;
    padding-bottom: 24px;
    border-bottom: 2px solid transparent;
  }
  .program-management-sidebar-menu-card li.active {
    padding-left: 0;
    border-left: 0;
    border-bottom: 2px solid #2774ae;
    color: #2774ae;
  }
}
@media only screen and (max-width: 560px) {
  .program-management-sidebar-card {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
  }
  .program-management-sidebar-card:first-child {
    padding: 16px;
  }
  .program-management-sidebar-menu-card {
    justify-content: flex-start;
    background: #fafafa;
    border: none;
    height: 56px;
    padding: 0 16px;
  }
  .program-management-sidebar-menu-card ul {
    justify-content: space-between;
  }
  .program-management-sidebar-menu-card li {
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 16px;
  }
}
/* Program Management Select Styles */
@media only screen and (min-width: 561px) {
  .program-management-select {
    border-radius: 4px;
    padding: 4px;
    border: 1px solid lightgray;
    font-size: 14px;
    font-family: AvenirHeavy;
    width: 100%;
  }
  .program-management-select > .program-management-select__control {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .program-management-select__value-container {
    overflow: visible !important;
    padding-left: 0 !important;
  }
  .program-management-select__single-value {
    white-space: pre-line !important;
    line-height: 1.25;
  }
  .program-management-select__indicator-separator {
    display: none;
  }
  .program-management-select__dropdown-indicator {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

/*** Card Styles ***/
.program-management-content-card {
  width: 100%;
  background: #fff;
}

/***** CURSOR STYLES *****/
.pointer {
  cursor: pointer;
}

/***** LINE HEIGHT STYLES *****/
.line-height-double {
  line-height: 1.5;
}

/***** FULL PAGE STATE STYLES *****/
.full-page-state {
  flex-direction: column;
  height: calc(100vh - 320px);
}
.full-page-state .fw-700.xxl-text {
  font-size: 34px;
}
@media only screen and (max-width: 560px) {
  .full-page-state {
    flex-direction: column;
    height: calc(100vh - 176px);
    padding: 0 16px;
  }
  .full-page-state img {
    height: 180px;
  }
  .full-page-state .fw-700.xxl-text {
    font-size: 20px;
    text-align: center;
    max-width: 320px;
    line-height: 1.5;
  }
  .full-page-state .large-text {
    font-size: 14px;
    line-height: 1.5;
    max-width: 320px;
  }
}
.fit-width {
  max-width: fit-content;
}

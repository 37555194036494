/***** EDU PAGE STYLES *****/
@media only screen and (max-width: 560px) {
  .edu-page.page.container {
    padding: 0 0 56px 0;
    background: #fff;
    min-height: calc(100vh - 106px);
  }
  .edu-error-page {
    padding: 80px 16px;
  }
  .edu-error-page img {
    height: 200px;
    max-width: 100%;
  }
}
/***** EDU PAGE BREADCRUMBS STYLES *****/
.edu-page-breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edu-page-breadcrumbs .breadcrumbs-container {
  padding-top: 16px;
}
@media only screen and (min-width: 561px) {
  .edu-page-breadcrumbs .breadcrumbs-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .edu-page-breadcrumbs .breadcrumbs-container {
    padding-top: 0;
  }
}

/***** EDU PAGE MENU DROPDOWN STYLES *****/
.edu-page-menu-dropdown-box {
  padding: 24px 32px;
  width: 160px;
  max-width: 100%;
  z-index: 1;
  position: absolute;
  right: 30px;
}
.edu-page-menu-dropdown-box .active-link {
  color: #007ab5;
}
@media only screen and (max-width: 400px) {
  .edu-page-menu-title {
    max-width: 200px;
  }
}

/***** EDU SUBHEADER STYLES *****/
.edu-subheader {
  background: #fff;
  height: 56px;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 1;
}
.edu-subheader ul {
  display: flex;
  justify-content: space-between;
  height: 56px;
}
.edu-subheader ul li {
  display: flex;
  align-items: center;
  margin: 0 16px;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  height: 100%;
  font-family: AvenirHeavy;
  white-space: nowrap;
}
.edu-subheader ul li a {
  transition: opacity 300ms linear;
}
.edu-subheader ul li > a.active-link {
  color: #007ab5;
}
.edu-subheader ul li a:not(.active-link):hover {
  opacity: 0.5;
}
.edu-subheader .edu-program-link {
  min-width: 60px;
  text-align: center;
}
.edu-subheader .edu-program-link {
  border-bottom: 3px solid transparent;
  margin: 0 4px -4px 4px;
  padding: 4px 8px;
}
.edu-subheader .edu-program-link.active {
  border-bottom: 3px solid $accent-color;
}
.edu-expiration-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  background-color: #fff4ef;
  color: #ff9e57;
  line-height: 1.2;
  margin-bottom: 16px;
  div {
    display: flex;
    align-items: center;
  }
  i {
    font-size: 20px;
  }
  a {
    color: inherit;
    text-transform: uppercase;
    margin-left: 16px;
  }
}
.edu-expiration-banner.expired {
  background-color: #fef0ef;
  color: #ff6b6b;
}
@media only screen and (max-width: 560px) {
  .edu-expiration-banner {
    margin: 0px 16px;
    display: block;
    div {
      align-items: start;
    }
    a {
      display: block;
      margin-top: 8px;
      margin-left: 32px;
    }
  }
}
.edu-courses-dropdown-container {
  position: relative;
}
.edu-courses-dropdown-container:hover .edu-courses-dropdown-box {
  opacity: 1;
  visibility: visible;
  width: 136px;
}
.edu-courses-dropdown-box {
  position: absolute;
  top: 40px;
}
.edu-courses-dropdown-box a {
  font-family: AvenirRoman;
  text-transform: none;
}

/***** EDU FORM (ENROLLMENT/SETTINGS) STYLES *****/
.edu-card {
  box-shadow: none;
}
.edu-form-section {
  border-top: 1px solid #edecec;
  margin-top: 24px;
  padding-top: 24px;
}
.edu-card .custom-input.custom-message {
  margin-left: 40px;
  width: calc(100% - 40px);
}
.edu-form-section .profile-pic-container img {
  height: 40px;
  width: auto;
  border-radius: 0;
}
.edu-form-btns .error-message {
  bottom: -20px;
  right: 0;
}
@media only screen and (max-width: 560px) {
  .edu-card {
    padding: 0;
    border: none;
  }
  .edu-card .page-title {
    padding: 24px 16px 0;
    width: 100%;
    border-top: 1px solid #edecec;
  }
  .edu-form-section {
    padding: 24px 16px 0;
  }
  .edu-form-section .dropzone {
    overflow: hidden;
  }
  .edu-form-btns {
    flex-direction: column-reverse;
    padding: 24px 16px 16px;
    border-top: 1px solid #edecec;
    margin: 24px 0 -56px;
  }
  .edu-form-btns .btn-accent {
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  .edu-form-btns .btn-light {
    background: transparent;
    text-decoration: underline;
    color: $accent-text;
    text-transform: none;
    margin-top: 0;
    border: none;
  }
  .edu-form-btns .error-message {
    bottom: 12px;
    width: 100%;
    text-align: center;
  }
}
.edu-page .copy-code-container {
  min-height: 16px;
}
.edu-page .copy-code-container textarea {
  background-color: transparent;
  height: 16px;
}
.edu-enrollment-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}

/***** ENROLLMENT COMPLETED STYLES *****/
@media only screen and (max-width: 1040px) {
  .edu-page .raffles-link {
    margin-top: 0;
  }
}
/***** ALL/MY COURSES LIST STYLES *****/
/*** Header Styles ***/
.edu-courses-header {
  padding-bottom: 32px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 560px) {
  .edu-courses-header {
    padding: 24px 16px 16px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 560px) {
  .mobile-filters-dropdown {
    position: absolute;
    display: block;
    background: #fff;
    box-shadow:
      0 2px 5px -2px rgba(0, 0, 0, 0.12),
      0 2px 18px 2px rgba(0, 0, 0, 0.08);
    padding: 16px;
    border-radius: 3px;
    z-index: 1;
    right: 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms linear;
  }
  .mobile-filters-dropdown.open {
    opacity: 1;
    visibility: visible;
  }
}

.edu-courses-add-new-schedule-btn {
  display: inline-flex;
  min-width: 240px;
}
/* Border Styles */
.edu-courses-header-border {
  position: absolute;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.04);
  border-bottom: none;
  margin-top: 32px;
}
@media only screen and (max-width: 560px) {
  .edu-courses-header-border {
    margin-top: 24px;
  }
}
/*** List Styles ***/
.edu-courses-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 320px));
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  max-width: 992px;
  margin: 0 auto;
  justify-content: center;
}
.edu-courses-cards-category-grid > div,
.edu-courses-cards-category-grid .edu-courses-cards-grid {
  display: contents;
}
.edu-courses-section-title {
  grid-column: 1 / -1;
  text-align: center;
  color: $accent-text;
  text-transform: uppercase;
  font-size: 18px;
}
.edu-course-card.card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.edu-course-card img {
  margin: -16px;
  width: calc(100% + 32px);
  height: 176px;
  object-fit: cover;
  padding-bottom: 32px;
  border-radius: 3px 3px 0 0;
}
.edu-course-card-footer {
  border-top: 1px solid #edecec;
  margin: 16px -16px 0 -16px;
  padding: 16px 16px 0 16px;
}
@media only screen and (max-width: 560px) {
  .courses-list {
    padding: 0 16px;
    justify-content: center;
  }
  .pagination.courses-list-pagination {
    padding: 0 16px;
  }
  .edu-courses-cards-grid {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 320px;
  }
}

/***** COURSE SCHEDULE STYLES *****/
.course-dates-label {
  font-size: 15px;
  line-height: 1.2;
}
.course-schedule-settings-form .edu-form-section label.accent-text-dark {
  width: 176px;
  min-width: 176px;
  margin-right: 8px;
}
.course-schedule-settings-form .cancel-schedule-section {
  border-top: 1px solid #edecec;
}
.course-schedule-settings-form .cancel-schedule-section .btn-light {
  padding: 0 32px;
}
.course-schedule-settings-form
  .course-schedule-semester-settings
  .custom-select {
  width: 164px;
}
@media only screen and (min-width: 561px) {
  .course-schedule-settings-form .edu-form-section label.address-label {
    padding-top: 8px;
  }
}
@media only screen and (max-width: 560px) {
  .course-schedule-settings-form {
    padding: 0;
  }
  .course-schedule-settings-form > .flex {
    padding: 24px 16px 0;
    width: 100%;
    border-top: 1px solid #edecec;
  }
  .course-schedule-settings-form .mobile-block > label.accent-text-dark {
    display: block;
    margin-bottom: 8px;
    width: 100%;
  }
  .course-schedule-settings-form .custom-input,
  .course-schedule-settings-form .custom-select,
  .course-schedule-settings-form .react-datepicker-wrapper {
    width: 100%;
  }
  .course-schedule-settings-form .mobile-block.mb-8 {
    margin-bottom: 16px;
  }
  .course-schedule-settings-form .cancel-schedule-section {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
  .course-schedule-settings-form .cancel-schedule-section .btn-light {
    background: transparent;
    text-decoration: underline;
    color: $accent-text;
    text-transform: none;
    margin-top: 0;
    border: none;
    padding: 0;
    height: 32px;
  }
}
/*** Class Schedules Section Styles ***/
.class-schedules-grid {
  display: grid;
  grid-template-columns: 1fr 400px;
}
.class-schedules-error {
  line-height: 1.2;
  margin-bottom: 16px;
  margin-top: -8px;
}
@media only screen and (max-width: 560px) {
  .class-schedules-grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}
/*** Weekly Schedule Box Styles ***/
.weekly-schedule-box {
  background: #fafafa;
  padding: 16px;
  border-radius: 3px;
  margin-left: 24px;
}
.weekly-schedule-box .btn {
  width: 64px;
}
.weekly-schedule-box-container {
  margin-bottom: -16px;
}
// @media only screen and (max-width: )
@media only screen and (max-width: 736px) {
  .weekly-schedule-box-container {
    margin-bottom: 0;
    flex-direction: column;
  }
  .weekly-schedule-box {
    max-width: 100%;
    margin: -8px 0 16px 0;
  }
}
@media only screen and (max-width: 561px) {
  .weekly-schedule-box-container {
    display: flex;
    flex-direction: column-reverse;
  }
}

/***** COURSE DETAILS STYLES *****/
.course-details-card {
  border: 1px solid #d3d3d37d;
  max-width: 1040px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.course-details-header {
  margin: -32px -32px 16px;
  height: 256px;
}
.course-details-header img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 3px 3px 0 0;
}
.course-details-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.level-tag {
  font-size: 13px;
  background: #0c425d;
  border-radius: 20px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;
}
.course-details-classes {
  margin: 40px 16px 0;
  padding-left: 48px;
}
.course-details-classes .class-title:before {
  content: "";
  background: #0c425d;
  height: 16px;
  width: 16px;
  position: absolute;
  border-radius: 100%;
  left: -48px;
}
.course-details-classes > div:not(:last-child) .class-description:before {
  content: "";
  height: calc(100% + 24px);
  width: 1px;
  background: #0c425d;
  position: absolute;
  left: -40px;
  top: -8px;
}
@media only screen and (max-width: 560px) {
  .course-details-header {
    margin: 0 0 16px 0;
  }
  .course-details-header img {
    border-radius: 0;
  }
  .course-details-actions {
    flex-direction: row;
  }
  .course-details-actions .btn {
    width: 50% !important;
    height: 32px;
    line-height: 32px;
  }
  .course-details-actions .btn:first-of-type {
    margin-right: 12px;
  }
  .course-details-mobile-divider {
    height: 1px;
    background: #edecec;
    margin: 24px -16px;
  }
  .course-details-card .large-text {
    font-size: 16px;
  }
  .course-details-classes {
    margin: 40px 0 0 0;
    padding-left: 0;
  }
  .course-details-classes .class-title {
    padding-left: 24px;
  }
  .course-details-classes .class-title:before {
    left: 0;
  }
  .course-details-classes > div:not(:last-child) .class-description:before {
    display: none;
  }
}
/***** COURSE MANAGEMENT STYLES *****/
/*** Sidebar Styles ***/
.edu-sidebar-card {
  width: 256px;
  padding: 24px;
}
.edu-sidebar-menu-card {
  padding-bottom: 0;
}
.edu-sidebar-card-navigation p {
  border-left: 2px solid transparent;
  padding-left: 24px;
  margin-left: -24px;
  transition: all 300ms linear;
  outline: none;
}
.edu-sidebar-card-navigation p.active {
  border-left: 2px solid $accent-color;
  color: $accent-color;
}
/* Course Schedule Select Styles */
.edu-course-schedule-select {
  border: none;
  font-size: 14px;
  font-family: AvenirHeavy;
  width: 100%;
}
.edu-course-schedule-select > .edu-course-schedule-select__control {
  border: none;
  background: transparent;
  cursor: pointer;
}
.edu-course-schedule-select__value-container {
  overflow: visible !important;
}
.edu-course-schedule-select__single-value {
  white-space: pre-line !important;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.edu-course-schedule-select__indicator-separator {
  display: none;
}
.edu-course-schedule-select__dropdown-indicator {
  color: rgba(0, 0, 0, 0.87) !important;
}
@media only screen and (max-width: 1040px) {
  .edu-sidebar-card {
    width: 100%;
  }
  .edu-sidebar-menu-card {
    background: transparent;
    border: none;
    box-shadow: none;
    height: 56px;
    padding: 0 24px;
  }
  .edu-sidebar-menu-card .edu-sidebar-card-navigation {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: auto;
  }
  .edu-sidebar-card-navigation::-webkit-scrollbar {
    display: none;
  }
  .edu-sidebar-menu-card .edu-sidebar-card-navigation p {
    height: 100%;
    margin: 0 16px;
    padding-left: 0;
    text-transform: none;
    font-family: AvenirRoman;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-left: none;
    border-right: none;
  }
  .edu-sidebar-menu-card .edu-sidebar-card-navigation p.active {
    padding-left: 0;
    border-left: 0;
    border-bottom: 2px solid #2774ae;
    color: #2774ae;
  }
}
@media only screen and (max-width: 560px) {
  .edu-sidebar-card {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
  }
  .edu-sidebar-card:first-child {
    width: 100%;
    border: none;
    margin-bottom: 0;
  }
  .edu-sidebar-menu-card {
    margin-top: 0;
  }
  .edu-sidebar-menu-card {
    background: #fafafa;
    border: none;
    height: 56px;
    padding: 0 16px;
  }
  .edu-sidebar-menu-card ul {
    justify-content: space-between;
  }
  .edu-sidebar-menu-card .edu-sidebar-card-navigation {
    justify-content: flex-start;
  }
}
/*** Card Styles ***/
.edu-content-card {
  width: 100%;
}
.edu-content-card .edu-students-card {
  max-width: 100%;
}
@media only screen and (min-width: 1041px) {
  .edu-content-card {
    max-width: calc(100% - 280px);
  }
}
/*** Attendance Styles ***/
.attendance-class-tabs {
  display: flex;
  align-items: center;
}
.attendance-class-tabs .link-text-secondary {
  text-transform: uppercase;
  font-size: 12px;
  padding: 16px;
}
.attendance-class-tabs .link-text-secondary.active {
  color: #2774ae;
  padding-bottom: 14px;
  border-bottom: 2px solid #2774ae;
}
.attendance-class-title {
  padding: 16px;
}
.attendance-table-header-row {
  @extend .paginated-table-header-row;
  grid-template-columns: 160px 1fr;
  padding: 12px 16px;
}
.attendance-table-class-schedules-grid {
  display: grid;
  width: 100%;
}
.attendance-table-row {
  @extend .paginated-table-row;
  grid-template-columns: 160px 1fr;
  padding: 12px 16px;
  grid-gap: 0;
}
/* Jewish U Styles */
.attendance-table-class-schedules-grid-JewishU {
  grid-template-columns: repeat(4, 25%);
}
/* Your Israel Styles */
.attendance-table-class-schedules-grid-YourIsrael {
  grid-template-columns: repeat(6, 16.6667%);
}
/* Sinai Scholars Styles */
.attendance-table-class-schedules-grid-SinaiScholars {
  grid-template-columns: repeat(8, 12.5%);
}
@media only screen and (max-width: 560px) {
  .attendance-class-tabs
    + .custom-select
    .custom-select__dropdown-indicator
    svg {
    color: rgba(0, 0, 0, 0.87);
  }
  .attendance-class-tabs + .custom-select .custom-select__control {
    border: none;
    padding: 0 16px;
    height: 40px;
    margin-top: 8px;
  }
  .attendance-class-tabs + .custom-select .custom-select__menu {
    max-width: 96%;
    left: 2%;
  }
  .attendance-class-tabs + .custom-select .custom-select__value-container {
    height: 40px;
  }
  .attendance-class-tabs + .custom-select .custom-select__single-value {
    font-size: 16px;
    font-family: AvenirHeavy;
    text-overflow: unset;
    white-space: normal;
    line-height: 1.25;
  }
  .attendance-card {
    padding: 24px 0;
  }
  .attendance-card .page-title {
    padding-left: 8px;
  }
  .attendance-class-title {
    padding: 12px;
  }
  .attendance-table {
    z-index: 1;
    overflow: auto;
    border-top: 1px solid #edecec;
    border-bottom: 1px solid #edecec;
  }
  .attendance-table::-webkit-scrollbar {
    display: none;
  }
  .attendance-table-header-row {
    margin-bottom: 0;
    border-bottom: none;
    font-size: 12px;
    grid-template-columns: 80px 1fr;
    grid-gap: 16px;
    position: relative;
    border-top: none;
  }
  .attendance-table-header-row > *:first-child {
    position: sticky;
    left: 16px;
    background: #fff;
    z-index: 1;
    height: 100%;
  }
  .attendance-table-row {
    background: #fff;
    border-top: 1px solid #edecec;
    border-radius: 0;
    padding: 8px 16px;
    position: relative;
    grid-template-columns: 80px 1fr;
    grid-gap: 16px;
    position: relative;
  }
  .attendance-table-row > *:first-child {
    position: sticky;
    left: 16px;
    background: #fff;
    z-index: 1;
    height: 100%;
  }
  .attendance-table-class-schedules-grid {
    grid-gap: 8px;
  }
  .attendance-table-class-schedules-grid > .flex {
    display: block;
  }
  .attendance-table-class-schedules-grid .tooltip-container.ml-8 {
    margin-left: 0;
    margin-top: 4px;
  }
  .attendance-table-class-schedules-grid .tooltip-container i {
    font-size: 14px;
  }
  .attendance-table-class-schedules-grid .tooltip {
    right: -16px;
    margin-bottom: 0;
    bottom: 0;
  }
}

/***** STUDENT LIST STYLES *****/
.edu-students-filters {
  max-width: calc(100% - 96px);
  margin-right: 8px;
}
.edu-students-filters .search-input {
  max-width: calc(100% - 220px);
}
.edu-students-card .students-table-row {
  grid-template-columns: 15% 24% 33.5% 10% 10%;
}
.students-table.students-list .students-table-row {
  grid-template-columns: 30% 23.5% 46.5%;
}
.students-table .rejected-chip {
  background-color: #ff6b6b;
  height: 20px;
  margin-left: 8px;
}

.edu-content-card .edu-students-card .students-table-row {
  grid-template-columns: 1.3fr 2fr 1fr 1fr 1fr 1fr;
}
@media only screen and (max-width: 1040px) {
  .edu-students-card {
    max-width: 100%;
  }
}
@media only screen and (max-width: 560px) {
  .edu-students-card {
    padding: 0;
  }
  .edu-students-header {
    padding: 24px 16px 0;
  }
  .edu-students-header > .flex {
    justify-content: space-between;
  }
  .edu-students-header > .flex > .flex:not(:last-child) {
    margin-right: 16px;
  }
  .edu-students-header .btn.btn-accent {
    font-size: 13px;
    padding: 0 8px;
    min-width: auto;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .edu-students-header .students-export-btn {
    margin-left: 0;
  }
  .edu-students-header + div > .flex {
    align-items: flex-start;
  }
  .edu-students-filters {
    padding: 0 16px;
  }
  .edu-students-filters .search-input {
    max-width: 100%;
  }
  .edu-students-card .students-table-row {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    padding: 8px 32px;
    width: calc(100% + 16px);
  }
  .student-table-row-mobile-small-text {
    font-size: 12px;
    color: $accent-text;
  }
}

.btn {
  border-radius: 3px;
  font-family: AvenirBook;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-size: 14px;
  transition:
    background 300ms linear,
    color 300ms linear,
    opacity 300ms linear;
  cursor: pointer;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  padding: 0 8px;
  min-width: 64px;
}
a.btn {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:focus {
  outline: none;
}
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn-large {
  height: 48px;
  line-height: 48px;
}
.btn-medium {
  height: 32px;
  line-height: 32px;
}
.btn-small {
  height: 24px;
  line-height: 24px;
}
.btn-fullWidth {
  width: 100%;
}
.btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.btn-accent:hover:not(:disabled) {
  opacity: 0.5;
  cursor: pointer;
}
.btn-secondary {
  background: $secondary-color;
  border: 1px solid $secondary-color;
}
.btn-secondary:hover:not(:disabled) {
  opacity: 0.5;
  cursor: pointer;
}
.btn-light {
  background: #edecec;
  color: #333;
  border: 1px solid #edecec;
}
.btn-light:hover:not(:disabled) {
  opacity: 0.4;
  cursor: pointer;
}
.btn.link-text {
  border: none;
  font-family: AvenirMedium;
}
.btn-link {
  width: 300px;
}

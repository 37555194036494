/***** TABLE HEADER STYLES *****/
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.table-header-left {
  display: flex;
  align-items: center;
}

.table-header-left p {
  margin-left: 8px;
}

.table-header-left p:nth-child(2) {
  height: 16px;
}

.view-select {
  border: none;
  font-size: 14px;
  font-family: AvenirHeavy;
  width: 64px;
  height: 36px;
}

.view-select > .view-select__control {
  border: none;
  background: transparent;
}

.view-select__single-value {
  color: $accent-color !important;
}

.view-select__indicator-separator {
  display: none;
}

.view-select__dropdown-indicator {
  color: $accent-color !important;
}

.table-header-left i.accent-text-secondary {
  vertical-align: middle;
  font-size: 16px;
}

@media only screen and (max-width: 560px) {
  .table-header {
    display: block;
  }

  .table-header-left,
  .view-select select {
    font-size: 11px;
  }

  .table-header > div:first-child {
    display: block !important;
  }
}

/***** TABLE HEADER ROW STYLES *****/
.paginated-table-header-row {
  font-size: 14px;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 800;
  display: grid;
  border-bottom: 1px solid lightgray;
}

/***** TABLE ROW STYLES *****/
.paginated-table-row {
  border-bottom: 1px solid lightgray;
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 3px;
  margin-bottom: 8px;
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  display: grid;
  grid-gap: 16px;
  line-height: 1.5;
  align-items: center;
}

a.paginated-table-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: 560px) {
  .full-width-table {
    margin: 0 -16px;
  }

  .table-rows {
    height: calc(100vh - 188px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-rows .table-row {
    margin: 0;
    border-radius: 0;
  }
}

/***** TABLE PAGINATION STYLES *****/
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 56px;
  width: 100%;
}

.pagination-no-margin-bottom {
  margin-bottom: 0px !important;
}

.pagination ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  align-items: center;
  margin-bottom: 0;
}

.pagination li {
  margin: 4px;
  padding: 4px;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms linear;
}

.pagination li:hover {
  opacity: 0.5;
}

.pagination li.active {
  background: $accent-color;
  color: #fff;
}

.pagination-arrow i {
  vertical-align: middle;
  font-size: 16px;
}

.pagination-arrow:first-child {
  margin-right: 8px;
  margin-left: 0;
}

.pagination-arrow:last-child {
  margin-left: 8px;
  margin-right: 0;
}

@media only screen and (max-width: 400px) {
  .pagination li {
    min-width: 20px;
    height: 20px;
    width: 20px;
  }
}

/***** INSTALL BANNER STYLES *****/
.install-banner {
  position: fixed;
  z-index: 220;
  margin: 20px 20px 40px 20px;
  width: calc(100vw - 40px);
  bottom: 64px;
  width: 440px;
  right: 0;
  max-width: 98%;
}
.install-banner .small-text {
  text-decoration: underline;
}

/*** Install Banner Android Styles ***/
.install-banner-android {
  padding: 24px 24px 12px 24px;
  right: 8px;
}
.install-banner-android .dismiss-label {
  padding: 0px 0px 12px 0px;
  margin-right: 16px;
}
.install-banner-android img {
  height: 40px;
  width: 40px;
}
.install-banner-android button {
  padding: 0 12px;
  float: right;
  margin-bottom: 12px;
}

/*** Install Banner IOS Styles ***/
.install-banner-ios {
  padding: 8px 16px 16px;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  max-width: 96%;
}
.install-banner-ios .message {
  margin: 16px 0 8px;
  line-height: 1.5;
}
.install-banner-ios img {
  height: 16px;
  margin: 0 4px;
  position: relative;
  top: 2px;
}

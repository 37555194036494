/***** TABS STYLES *****/
.tabs {
  display: flex;
  align-items: center;
}
.tab {
  align-items: center;
  border-bottom: 2px solid transparent;
  color: $accent-text;
  cursor: pointer;
  display: flex;
  font-family: AvenirMedium;
  height: 54px;
  transition: all 300ms linear;
}
.tab.active {
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
  font-family: AvenirHeavy;
}

.simple-keyboard {
  position: absolute;
  z-index: 2;
  top: 34px;
  left: 0;
  min-width: 280px;
}
.simple-keyboard.hg-theme-default {
  background: #f5f5f5;
}
@media only screen and (max-width: 560px) {
  .simple-keyboard {
    top: 52px;
  }
}

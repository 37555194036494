/***** TRIP PAGE STYLES *****/
.trip-page {
  padding: 40px;
  min-height: calc(100vh - 72px);
}
.trip-page-background {
  background: #fff;
}
.banner-wrapper + .header + .page-wrapper .trip-page {
  min-height: calc(100vh - 120px);
}
@media only screen and (max-width: 1040px) {
  .trip-page {
    min-height: calc(100vh - 56px);
    padding: 0 0 24px;
  }
  .trip-page .account-info-header {
    margin: 0 0 32px 0;
  }
}
@media only screen and (max-width: 560px) {
  .trip-page {
    padding: 0;
  }
  .trip-page > .container {
    padding: 0;
  }
  .trip-page .account-info-header {
    margin: 0;
    padding: 16px;
  }
  .trip-page .flex.mobile-block > label {
    display: block;
    margin-bottom: 8px;
  }
}

/*** Divider Styles ***/
.trip-page-divider {
  background: #f5f5f5;
  margin: 24px -40px 48px;
  padding: 12px 40px;
  height: 48px;
}

/*** Stepper Styles ***/
.trip-page-stepper {
  @extend .kinus-page-wizard-steps;
  background: #fff;
  margin: 0 0 24px 0;
  padding: 16px 20px;
  border-bottom: 1px solid #edecec;
  //TODO: figure out why i have to add this
  overflow-x: hidden;
}
.stepper-line {
  position: relative;
  height: 1px;
  width: calc(100% - 190px);
  background: rgba(0, 0, 0, 0.24);
  top: -12px;
  margin: 0 -96px;
}
.stepper-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.stepper-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.stepper-step:first-child {
  margin-left: -96px;
}
.stepper-step:last-child {
  margin-right: -96px;
}
.stepper-step-circle {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.24);
  background: #f5f5f5;
  z-index: 1;
  position: relative;
}
.stepper-step-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
  margin-top: 8px;
}
.stepper-step.active .stepper-step-circle {
  border-color: $accent-color;
  background: $accent-color;
}
.stepper-step.active .stepper-step-title {
  color: $accent-color;
}
.stepper-btns {
  display: flex;
  justify-content: space-between;
}
.trip-form-error {
  bottom: -20px;
  right: 0px;
}
@media only screen and (max-width: 1040px) {
  .banner-wrapper + .header + .page-wrapper .trip-page-stepper {
    top: 104px;
  }
  .trip-page-stepper {
    padding: 12px 16px;
  }
}
@media only screen and (max-width: 768px) {
  .stepper-step:first-child {
    margin-left: -72px;
  }
  .stepper-step:last-child {
    margin-right: -72px;
  }
}
@media only screen and (max-width: 560px) {
  .stepper-step-title {
    font-size: 12px;
  }
  .stepper-step:first-child {
    margin-left: -88px;
  }
  .stepper-step:last-child {
    margin-right: -88px;
  }
}
@media only screen and (max-width: 440px) {
  .stepper-btns {
    flex-direction: column-reverse;
  }
  .stepper-btns .btn-light {
    margin-top: 0;
    text-align: center;
    background: transparent;
    text-decoration: underline;
    font-size: 12px;
    text-transform: none;
    color: $accent-text-dark;
    width: 100%;
    border: none;
  }
}

/*** Trip Enrollment Form Styles ***/
.trip-form-cards-container {
  align-items: flex-start;
}
.trip-form-cards-container > div {
  position: sticky;
  top: 120px;
}
.trip-form-card {
  padding: 16px 0 32px;
}
.trip-page-form-container {
  @extend .kinus-page-form-container;
  width: 100%;
}
.trip-page-form-section {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 24px;
  //TODO: figure out why i have to add this
  overflow-x: hidden;
  .add-icon {
    .MuiSvgIcon-root {
      font-size: 20px;
    }
    .MuiIconButton-root {
      padding: 0px;
      margin-left: 4px;
      &:hover {
        background-color: #ffffff !important;
        opacity: 0.5;
        transition: opacity 300ms linear;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
.shluchim-section {
  overflow-x: unset !important;
}
.trip-page-form-section:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.trip-page-form-container .xxl-text {
  font-size: 22px;
}
.trip-page-form-section .flex.custom-checkbox-container.mb-12:last-child {
  margin-bottom: 0;
}
.trip-page-form-section
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
.trip-page-form-section .multiselect {
  min-height: 40px;
  height: auto;
}
.trip-page-form-section .multiselect > div:first-of-type {
  align-items: flex-start;
  min-height: 40px;
  height: auto;
}
.trip-page-form-section .multiselect .multiselect__value-container {
  min-height: 40px;
}
.trip-page-form-section .multiselect__menu-list {
  max-height: 200px;
}
// .trip-page-form-section .multiselect > div > div {height: 100%;}
.trip-page-form-section .multiselect__menu {
  margin-left: 1px;
}
.trip-page-form-section textarea.custom-input {
  height: 80px;
  resize: none;
  width: 100%;
  padding: 8px;
}
/*** Attendees ***/
.chaperone-wrapper {
  .MuiIconButton-root {
    padding: 0px;
    margin-bottom: 8px;
    margin-left: 12px;
    &:hover {
      background-color: #ffffff !important;
      opacity: 0.5;
      transition: opacity 300ms linear;
    }
  }
}
.chaperone {
  height: 32px;
  width: 94%;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 8px;
}
/*** New Chaperone Modal ***/
.modal-container.chaperone-modal-container {
  z-index: 1 !important;
}
.chaperone-modal.card {
  width: 620px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
  input.custom-input:read-only {
    border: 1px solid #e9e6e6;
  }
}
.chaperone-modal label {
  font-weight: normal;
  font-size: 13px;
  min-width: 86px;
}
.chaperone-field {
  min-width: 50%;
}
.chaperone-modal .btn {
  padding: 0 12px;
}
.chaperone-modal .custom-input {
  width: 178px;
}
.chaperone-modal-separator {
  border: 0.5px solid #edecec;
  margin: 28px -24px 22px;
}
.chaperone-modal .terms-text {
  font-size: 16px;
  margin-top: 14px;
}
.chaperone-modal .phone-input {
  padding-left: 10px;
}
.chaperone-modal .phone-input .MuiInputBase-input {
  padding-left: 2px;
  padding-top: 6px;
  font-size: 13px;
}
.cancel-btn {
  color: black;
}
.save-btn {
  color: white;
  background-color: $accent-color;
  min-width: 80px;
}
@media only screen and (max-width: 1040px) {
  .trip-enrollment-title {
    padding-top: 24px;
  }
  .trip-form-cards-container > div {
    position: unset;
  }
}
@media only screen and (max-width: 560px) {
  .trip-enrollment-title {
    font-size: 20px;
    margin-bottom: 0;
    padding: 16px 16px 0 16px;
  }
  .trip-page-form-container.container {
    padding: 0 16px 16px;
  }
  .trip-page-form-container .xxl-text {
    font-size: 20px;
    font-family: AvenirHeavy;
  }
  .trip-page-form-section {
    margin: 0 -16px 24px;
    padding: 0 16px 24px;
  }
  .chaperone-modal .custom-input {
    width: 300px;
  }
  .chaperone-modal .terms-text {
    font-size: 13px;
  }
}
/* General Settings Styles */
.additional-chaperone-form label {
  width: 148px;
  min-width: 148px;
}
.additional-chaperone-form .custom-input,
.additional-chaperone-form .phone-input {
  width: 208px;
  max-width: 100%;
}
.additional-chaperone-form .phone-input {
  border: none;
  height: 32px;
}
@media only screen and (max-width: 560px) {
  .additional-chaperone-form .custom-input,
  .additional-chaperone-form .phone-input {
    width: 100%;
  }
}
/* Pricing Styles */
.trip-form-dates-inputs label {
  width: 164px;
  min-width: 148px;
}
.trip-form-dates-inputs .custom-input {
  width: 208px;
  min-width: 208px;
  max-width: 100%;
}
.trip-form-pricing-inputs label {
  width: 240px;
  min-width: 240px;
}
.promo-codes-grid {
  max-width: 840px;
}
.promo-codes-grid-row {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr 1.5fr 2.5fr;
  grid-gap: 8px;
  background: rgba(83, 183, 232, 0.12);
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  min-height: 40px;
  align-items: center;
  width: calc(100% - 72px);
}
.promo-codes-grid-row p {
  margin-bottom: 0;
  color: #007ab5;
}
.promo-codes-grid .tooltip {
  top: -2px;
  left: 48px;
  height: 20px;
}
.settings-promo-codes-grid .tooltip {
  top: -24px;
  left: auto;
  right: 0;
  height: 20px;
}
.promo-code-details {
  display: contents;
}
.promo-code-modal.card {
  width: 400px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}
.promo-code-modal label {
  font-weight: normal;
  width: 96px;
  min-width: 96px;
}
.promo-code-modal .limit-input {
  max-width: 154px;
}
.delete-promo-modal.card {
  padding: 24px;
}
@media only screen and (max-width: 1040px) {
  .promo-codes-grid-row {
    grid-template-columns: 1.5fr 2fr 1fr 1.5fr 1fr;
  }
}
@media only screen and (max-width: 560px) {
  .trip-form-dates-inputs label {
    width: 100%;
  }
  .trip-form-dates-inputs .custom-input {
    width: 100%;
  }
  .trip-form-dates-inputs .accent-text-dark.ml-24 {
    margin-left: 0;
    margin-top: 4px;
  }
  .trip-form-pricing-inputs label {
    width: 100%;
  }
  .trip-form-pricing-inputs .custom-input {
    width: 100%;
  }
  .trip-form-pricing-inputs .accent-text-dark.ml-24 {
    margin-left: 0;
    margin-top: 4px;
  }
  .promo-codes-grid-row {
    grid-template-columns: 1fr;
    height: auto;
    grid-gap: 4px;
  }
  .promo-code-details {
    display: block;
  }
  .closed .promo-code-details {
    display: none;
  }
  .promo-code-modal .custom-input {
    width: 100% !important;
  }
  .promo-code-modal .limit-input {
    max-width: 186px;
  }
}
/* Tours Styles */
.trip-tours-form-label,
.trip-page-form-label {
  width: 176px;
  min-width: 176px;
  margin-right: 8px;
  color: $accent-text-dark;
}
.trip-tours-form-label.error {
  color: $error-color;
}
.trip-tours-form-numeric-input {
  min-width: 80px;
  max-width: 80px;
  margin-right: 8px;
}
.trip-page-form-section .radio-button-container.mb-8:last-child {
  margin-bottom: 0;
}
.trip-page-tour-schedule .custom-checkbox[type="checkbox"] + label {
  font-size: 13px;
  top: 2px;
}
.trip-page-tour-schedule .custom-checkbox[type="checkbox"] + label:before {
  position: relative;
  top: -2px;
  margin-right: 12px;
}
.trip-page-tour-schedule
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  top: -1px;
}
.transportation-dropdown {
  .MuiFormControl-root {
    min-width: 340px;
    height: 40px;
  }
  .MuiOutlinedInput-root {
    height: 40px;
  }
  .MuiOutlinedInput-input {
    padding: 8px;
  }
}
.transportation-dropdown-note {
  font-size: 12px;
  color: orange;
  max-width: 330px;
  padding: 12px 12px 16px 12px;
}
.slot-text {
  font-family: "AvenirBook";
  font-style: italic;
  margin-left: 8px;
}
.none {
  color: red;
}
.low {
  color: orange;
}
.tours-warning {
  color: #ff7109;
  max-width: 230px;
  margin-left: 8px;
  font-size: 13px;
  line-height: 1.25;
}
.tours-error {
  font-size: 13px;
  line-height: 1.25;
}
.tour-opt-out-warning-msg {
  width: 100%;
  border-radius: 3px;
  background-color: rgb(255, 241, 241);
  padding: 8px;
  font-size: 13px;
  line-height: 1.25;
  margin-bottom: 16px;
  color: red;
  .MuiSvgIcon-root {
    font-size: 14px;
    margin-right: 4px;
    color: red;
  }
}
.tour-opt-out-modal {
  .student-registration {
    font-size: 14px;
    font-family: "AvenirHeavy";
    display: flex;
    align-items: center;
    grid-gap: 12px;
    margin-bottom: 8px;
    .MuiChip-label {
      overflow: visible;
      font-size: 10px;
      color: white;
    }
    .MuiChip-sizeSmall {
      height: 18px;
    }
  }
}
@media only screen and (max-width: 560px) {
  .trip-tours-form-label,
  .trip-page-form-label {
    display: flex;
    margin-bottom: 4px;
    width: 100%;
  }
  .trip-tours-schedule-label {
    margin-bottom: 8px;
  }
  .trip-page-form-section .radio-container {
    width: 90%;
  }
  .trip-page-form-section .radio-container input + label {
    width: 50% !important;
  }
  .mobile-error {
    max-width: 100%;
    margin-top: 8px;
    margin-left: 0px;
  }
  .slot-text {
    margin-left: 0px;
  }
}
/* Terms Styles */
.trip-page-form-section.term .custom-checkbox[type="checkbox"] + label {
  align-items: flex-start;
}
.trip-page-form-section.term .custom-checkbox[type="checkbox"] + label:before {
  top: 3px;
  position: relative;
}
.trip-page-form-section.term
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  top: 4px;
}
.term .custom-checkbox[type="checkbox"] + label.error {
  color: $error-color;
}
@media only screen and (max-width: 560px) {
  .term .custom-checkbox[type="checkbox"] + label.flex-align-center {
    align-items: flex-start;
  }
}
/* Payment Styles */
.trip-page-form-section .cc-info-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.trip-billing-summary {
  max-width: 280px;
}
.billing-summary-promo-code {
  margin-left: 24px;
}
.new-payment-options {
  padding-top: 4px;
}
.new-payment-options .radio-button-container input + label {
  padding-left: 28px;
}
.trip-page-form-section.billing-address-section {
  overflow: visible;
}
.trip-page-form-section.billing-address-section .custom-select__menu-list {
  max-height: 120px;
}
@media only screen and (max-width: 560px) {
  .trip-page-form-section .cc-info-form {
    width: 100%;
  }
  .trip-billing-summary {
    max-width: 100%;
  }
}

/*** My Trip Styles ***/
/* Sidebar Card Styles */
.trip-sidebar-card {
  width: 256px;
  padding: 24px;
}
.trip-link {
  @extend .raffle-link;
}
.active-trip-link {
  @extend .active-raffle-link;
}
.trip-sidebar-card label {
  color: rgba(0, 0, 0, 0.48);
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
}
.trip-sidebar-card .program-contact-info {
  margin: 0 -24px 24px;
  padding: 0 24px 24px;
  border-bottom: 1px solid #edecec;
}
.trip-sidebar-card .program-contact-info p {
  margin-bottom: 0;
}
// TODO: add in when add registered students back in
// .trip-sidebar-card .program-external-link {border-bottom: 1px solid #edecec; margin: 0 -24px 24px; padding: 0 24px 24px;}
.trip-sidebar-card .program-external-link i {
  color: rgba(0, 0, 0, 0.2);
}
.trip-card {
  max-width: calc(100% - 280px);
}
@media only screen and (max-width: 1200px) {
  .trip-sidebar-card {
    width: 224px;
  }
  .trip-card {
    max-width: calc(100% - 248px);
  }
}
@media only screen and (max-width: 1040px) {
  .trip-page > .flex > .mr-24 {
    margin-right: 0;
  }
  .trip-sidebar-card {
    width: 100%;
  }
  .trip-sidebar-menu-card {
    background: transparent;
    border: none;
    box-shadow: none;
    height: 56px;
    padding: 0 24px;
  }
  .trip-sidebar-menu-card ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .trip-sidebar-menu-card ul li {
    height: 100%;
    margin: 0 16px;
  }
  .trip-link {
    text-transform: none;
    margin: 0;
    font-family: AvenirRoman;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
  }
  .active-trip-link {
    padding-left: 0;
    border-left: 0;
    border-bottom: 2px solid $accent-color;
    color: $accent-color;
  }
  .trip-sidebar-card.mt-32 {
    margin-top: 0;
    margin-bottom: 24px;
    border: none;
    padding: 24px;
  }
  .trip-sidebar-card .program-contact-info {
    font-size: 12px;
    margin: 0 -16px 0;
    padding: 0 16px 24px;
    border-bottom: none;
  }
  .program-total-info {
    font-size: 12px;
    display: flex;
  }
  .program-total-info p {
    display: flex;
    flex-direction: column;
  }
  .program-total-info p span:first-child {
    margin-bottom: 8px;
  }
  .trip-card {
    max-width: 100%;
  }
}
@media only screen and (max-width: 560px) {
  .trip-sidebar-card {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
  }
  .trip-sidebar-menu-card {
    margin-top: 0;
  }
  .trip-sidebar-menu-card {
    background: #fafafa;
    border: none;
    height: 56px;
    padding: 0 16px;
  }
  .trip-sidebar-menu-card ul {
    justify-content: space-between;
  }
  .trip-sidebar-menu-card.no-settings ul {
    justify-content: space-evenly;
  }
  .trip-sidebar-card.mt-32 {
    padding: 24px 16px;
    margin-bottom: 0;
  }
  .trip-sidebar-card .program-external-link {
    border-top: 1px solid #edecec;
    margin: 0 -16px;
    padding: 24px 16px 0 16px;
  }
  // TODO: add in when add registered students back in
  // .trip-sidebar-card .program-external-link {border-top: 1px solid #edecec; margin: 0 -16px 24px; padding: 16px;}
  .trip-card {
    box-shadow: none;
    border-radius: 0;
    padding: 24px 16px 88px 16px;
    border: none;
    border-top: 1px solid #edecec;
  }
}
/* Students Styles */
.filter-by-select .custom-select > div,
.filter-by-select .custom-select > div:hover {
  border: none;
  padding: 0 8px;
  width: 144px;
}
.filter-by-select .custom-select__single-value {
  color: $accent-color;
}
.students-export-btn {
  padding: 0 24px;
  margin-left: auto;
}
.students-table-row {
  grid-template-columns: 1.3fr 2fr 1fr 1fr 1fr;
  padding: 12px 16px;
  grid-gap: 16px;
}
.students-table-row .link-text-secondary .fw-700 {
  color: rgba(0, 0, 0, 0.87);
}
.desktop-display-contents {
  display: contents;
}
.desktop-display-contents > div:nth-child(2) {
  word-break: break-all;
}
.student-details-modal {
  width: 704px;
  max-width: 100%;
  padding: 32px 40px;
}
.student-details-modal-section:not(:last-child) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.student-recommendation-modal {
  width: 960px;
  max-width: 100%;
  padding: 24px;
}
.student-recommendation-modal.submitted {
  width: 700px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.student-recommendation-header {
  @extend .student-details-header;
  display: flex;
  justify-content: space-between;
  margin: -24px;
  margin-bottom: 24px;
  padding: 24px;
  border-bottom: 1px solid #edecec;
}
.student-recommendation-footer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin: -24px;
  margin-top: 24px;
  padding: 24px;
  border-top: 1px solid #edecec;
  .btn {
    width: 110px;
  }
  .error-message {
    bottom: 6px;
  }
}
.modal-error-icon i {
  border: 3px solid $error-color;
  padding: 16px;
  border-radius: 100%;
  font-size: 72px;
  color: $error-color;
}
.student-status-tag {
  height: 20px;
  line-height: 20px;
  margin-left: 16px;
}
.student-recommendation-tag {
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: #ff6b6b;
  border: 1px solid #ff6b6b;
  border-radius: 12px;
  padding: 4px 7px;
}
.student-recommendation-tag.submitted {
  color: #4fcec5;
  border: 1px solid #4fcec5;
}
.student-info label {
  width: 192px;
  min-width: 192px;
}
.student-tour:not(:last-child) {
  margin-bottom: 24px;
}
.btn-reject {
  background: #ec5150;
  color: #fff;
  border: 1px solid #ec5150;
}
.btn-reject:hover {
  background: #fff;
  color: #ec5150;
}
.btn-accept {
  background: #63c674;
  color: #fff;
  border: 1px solid #63c674;
}
.btn-accept:hover {
  background: #fff;
  color: #63c674;
}
.student-confirmation-modal {
  width: 600px;
  min-height: 260px;
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    .cancel-btn {
      margin-right: 12px;
    }
    .reject-btn {
      background: #ff6b6b;
    }
    .reject-btn:hover {
      background: #c45555;
    }
    .accept-btn {
      background: #4fcec5;
    }
    .accept-btn:hover {
      background: #38918b;
    }
  }
}
.send-email-checkbox {
  .MuiCheckbox-root {
    padding-top: 7px;
  }
}
.preview-btn {
  display: flex;
  margin-bottom: 22px;
  align-items: center;
  .visibility-icon {
    margin-right: 8px;
  }
  .MuiButton-label {
    font-size: 12px;
  }
}
.email-preview-wrapper {
  border: 1px solid #d8d8dd;
  padding: 12px;
  margin-bottom: 16px;
  text-align: left;
}
.student-registration-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #0c425d;
  .action-card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 600px;
    max-width: 98%;
    min-height: 420px;
    max-height: 75%;
    flex-direction: column;
  }
  .action-btn {
    display: flex;
    align-items: center;
    padding: 0px 16px;
  }
}
@media only screen and (max-width: 768px) {
  .students-filters-header {
    display: block;
    margin-bottom: 0;
  }
  .students-filters-header .search-input {
    width: 100% !important;
    margin-bottom: 16px;
  }
  .students-filter-by {
    float: left;
    margin-left: 0;
  }
  .students-filters-header .students-export-btn {
    float: right;
    height: 32px;
    line-height: 32px;
    margin-bottom: 16px;
    padding: 0 16px;
  }
  .students-table {
    clear: both;
  }
}
@media only screen and (max-width: 560px) {
  .paginated-table-header-row.students-table-row {
    display: none;
  }
  .students-table-row {
    background: #fff;
    border-top: 1px solid #edecec;
    border-radius: 0;
    margin: 0 -16px;
    padding: 8px 72px 8px 48px;
    grid-template-columns: 1fr;
    position: relative;
  }
  .students-table-row:last-child {
    border-bottom: 1px solid #edecec;
  }
  .students-table-row .collapse-icon {
    position: absolute;
    left: 16px;
    top: 10px;
  }
  .student-actions {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  .desktop-display-contents {
    display: block;
  }
  .desktop-display-contents.mobile-flex {
    display: flex;
  }
  .students-table-row .desktop-display-contents .link-text-secondary .fw-700 {
    font-family: AvenirRoman;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
  .students-table-row .desktop-display-contents .link-text-secondary + .fw-700 {
    font-family: AvenirRoman;
  }
  .students-table-row.closed .student-details {
    display: none;
  }
  .student-mobile-label {
    width: 96px;
    min-width: 96px;
    font-size: 14px;
  }
  .student-mobile-label + p {
    font-size: 14px;
  }
  .student-details-modal {
    padding: 24px;
  }
  .student-details-header {
    margin: 0 -24px 24px;
    padding: 0 24px 24px;
    border-bottom: 1px solid #edecec;
    display: block;
  }
  .student-details-header > .flex {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .student-details-header .tag {
    margin-left: 0;
    margin-bottom: 16px;
    height: 24px;
    font-size: 14px;
  }
  .student-details-header i.material-icons {
    display: none;
  }
  .student-details-modal-section {
    font-size: 12px;
  }
  .student-info label {
    width: 168px;
    min-width: 168px;
  }
  .student-modal-btns .btn {
    width: 50%;
    justify-content: center;
  }
  .student-recommendation-modal .MuiFormControlLabel-label {
    font-size: 13px;
  }
  .student-recommendation-header {
    .xxl-text {
      font-size: 18px;
    }
    .large-text {
      font-size: 16px;
    }
  }
  .student-recommendation-footer .btn {
    width: "100%";
  }
}
/* Metrics Styles */
.number-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin-bottom: 24px;
}
.number-metrics > div {
  background: #f4f4f4;
  padding: 32px 8px;
  border-radius: 3px;
  text-align: center;
}
.number-metrics .xxl-text {
  font-size: 34px;
}
.metrics-two-column-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
.pie-chart-metrics {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}
.pie-chart-metrics > div {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 16px;
}
.trip-metrics .recharts-responsive-container {
  margin: 0 !important;
}
.metrics-pie-chart-labels > div:not(:last-child) {
  margin-bottom: 8px;
}
.metrics-pie-chart-label-square {
  height: 14px;
  width: 14px;
  min-width: 14px;
  margin-right: 8px;
}
.tour-metrics > div {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 16px;
}
.tour-metrics > div > div:first-child {
  padding-left: 40px;
  position: relative;
}
.tour-metrics > div > div:first-child:before {
  content: "";
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  background: $accent-color;
  position: absolute;
  left: 0;
  top: 0;
}
.tour-metrics > div > div:nth-child(2) {
  border-left: 1px solid #edecec;
  border-right: 1px solid #edecec;
  padding: 0 24px;
}
@media only screen and (max-width: 768px) {
  .metrics-two-column-grid {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 560px) {
  .number-metrics {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
  .number-metrics > div {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 12px 16px;
    align-items: center;
  }
  .number-metrics .xxl-text.mb-8 {
    margin-bottom: 0;
    font-size: 18px;
  }
}
/* Settings Styles */
.trip-settings-card {
  max-width: 100%;
}
.trip-settings-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.trip-settings-tabs li p {
  text-transform: uppercase;
  padding-bottom: 16px;
  border-bottom: 2px solid transparent;
  transition: all 300ms linear;
  text-align: center;
}
.trip-settings-tabs li p:hover {
  color: $accent-color;
  cursor: pointer;
}
.trip-settings-tabs li .active {
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
  cursor: pointer;
}
.trip-page-form-btns {
  max-width: 104px;
  padding-left: 16px;
}
.trip-page-form-btns > div {
  position: sticky;
  top: 168px;
}
.trip-page-form-btns .btn {
  width: 104px;
}
.trip-page-form-btns .error-message {
  top: 52px;
  font-size: 11px;
  line-height: 1.2;
}
.trip-settings-card .no-tours-label {
  font-family: AvenirHeavy;
  text-align: center;
}
@media only screen and (max-width: 1040px) {
  .trip-page-form-btns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .trip-page-form-btns .btn {
    margin-top: 16px;
  }
  .trip-page-form-btns > div {
    margin-right: 72px;
  }
}
@media only screen and (max-width: 560px) {
  .trip-page-form-btns {
    max-width: 100%;
  }
  .trip-page-form-btns > div {
    width: 100%;
    margin: 8px;
  }
  .trip-settings-page {
    min-height: calc(100vh - 232px);
    background: #fff;
  }
  .trip-settings-card {
    padding-top: 0;
    border-top: none;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 232px);
    padding-bottom: 104px;
  }
  .trip-settings-tabs {
    display: none;
  }
  .trip-settings-card > div:not(.trip-page-form-section) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .trip-settings-card
    > div:not(.trip-page-form-section)
    > .trip-page-form-section:first-child {
    flex: 1;
    align-items: center;
    display: flex;
  }
  .trip-page-form-btns {
    flex-direction: column-reverse;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    padding: 24px 16px 16px;
    border-top: 1px solid #edecec;
  }
  .trip-page-form-btns .btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  .trip-settings-card .no-tours-label {
    text-align: left;
  }
}

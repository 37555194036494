.breadcrumbs-container {
  min-height: 28px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 1040px) {
  .breadcrumbs-container {
    margin-top: 32px;
    min-height: 12px;
    padding: 0;
  }
}
@media only screen and (max-width: 560px) {
  .breadcrumbs-container {
    margin-top: 0;
    padding: 0 16px;
  }
}

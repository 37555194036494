/***** UPDATES PAGE STYLES *****/
.updates-page,
.update-details-page {
  padding: 24px 24px 40px 40px;
  background: #fff;
  min-height: 87vh;
}
/***** POST (CARD) STYLES *****/
.post-card {
  max-width: 85vw;
  padding: 16px 24px 24px 0;
  display: flex;
  margin-bottom: 24px;
  transition: box-shadow 300ms linear;
  height: 160px;
}
.post-card:hover {
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.post-card > img {
  height: 160px;
  width: 220px;
  min-width: 220px;
  object-fit: cover;
  margin: -16px 16px -24px 0;
  border-radius: 3px 0 0 3px;
}
.post-content {
  position: relative;
  line-height: 24px;
  height: 74px;
  overflow: hidden;
}
.post-content:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.5em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 75%
  );
}
@media only screen and (max-width: 560px) {
  .post-card {
    height: 80px;
  }
  .post-card > img {
    height: 80px;
    width: 96px;
    min-width: 96px;
  }
  .post-details p.large-text {
    font-size: 12px;
  }
  .post-details p.small-text {
    font-size: 10px;
  }
  .post-content {
    display: none;
  }
}

/***** MORE BTN STYLES *****/
.more-updates-btn {
  margin-top: 24px;
  width: 240px;
}
.more-updates-loader svg#loading {
  height: 40px;
}

/***** UPDATE DETAILS STYLES *****/
.update-feature-top {
  margin-top: 32px;
  margin-bottom: 16px;
  gap: 100px;
}
.update-feature-divider {
  border-top: 1px solid lightgray;
}
.update-featured-img > img {
  height: 380px;
  width: 680px;
  object-fit: cover;
  border: 1px solid #d3d3d33d;
}

@media only screen and (max-width: 560px) {
  .update-feature-top {
    margin-top: 0px;
    gap: 24px;
  }
  .update-featured-img > img {
    height: 180px;
    width: 288px;
  }
}

.post-body-container {
  margin-top: 16px;
  display: flex;
  line-height: 1.8;
}
.post-body p {
  margin-bottom: 16px;
}
.post-body p a,
.post-body ul a {
  color: $accent-color;
  transition: opacity 300ms linear;
  cursor: pointer;
}
.post-body p a:hover,
.post-body ul a:hover {
  opacity: 0.5;
}
.post-body strong,
.post-body b {
  font-family: AvenirHeavy;
}
.post-body ol,
.post-body ul {
  list-style-type: disc;
  padding: revert;
  margin: revert;
}
@media only screen and (max-width: 1040px) {
  .post-body {
    margin-left: 0;
    margin-top: 24px;
  }
}

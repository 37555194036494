.horizontal-menu {
  height: 40px;
  margin-top: 16px;
}
.horizontal-menu * {
  height: 100%;
}
.menu-wrapper--inner {
  display: flex;
}
.menu-item-wrapper {
  padding: 0 8px;
}
.menu-item {
  user-select: none;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  margin: 0 8px;
}
.menu-item-wrapper:focus {
  outline: none;
}
.menu-item-wrapper.active .menu-item {
  border-bottom: 4px white solid;
}
.scroll-menu-arrow {
  padding: 12px 0px;
  cursor: pointer;
}
.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.social-links {
  justify-self: flex-end;
}
.social-links i {
  font-size: 20px;
  color: $accent-color-dark;
  display: inline-block;
  margin-left: 12px;
}
.social-links i:hover {
  transition: all 300ms linear;
  transform: scale(1.15);
}

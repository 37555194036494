/***** ACCOUNT PAGES STYLES *****/
.account-page {
  display: grid;
  grid-template-columns: 1fr auto;
  height: 100vh;
}
@media only screen and (max-height: 680px) {
  .account-page {
    height: auto;
  }
}
/*** Account Page Left Styles ***/
.account-page-left {
  padding: 64px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 360px;
  box-sizing: content-box;
}
.account-header-mobile {
  background: linear-gradient(135deg, #ec5150 0%, #f3893d 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -16px 32px;
  padding: 0 16px;
}
.account-page-title {
  font-family: "AvenirBlack";
  font-size: 48px;
  margin-bottom: 32px;
}
.account-page-title-small {
  font-family: AvenirHeavy;
  font-size: 24px;
  margin-bottom: 16px;
}
.account-page-title-small + .accent-text {
  max-width: 344px;
  line-height: 1.5;
}
.account-form .input-container {
  height: 48px;
  width: 100%;
  margin-bottom: 16px;
}
.account-form .error-message {
  width: 100%;
  text-align: center;
}
@media only screen and (min-width: 1624px) {
  .account-page-left {
    width: 360px;
    box-sizing: content-box;
    justify-self: center;
  }
}
@media only screen and (max-width: 1300px) {
  .account-page-left {
    padding: 64px 80px;
  }
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .account-page-left {
    min-width: 424px;
    box-sizing: border-box;
  }
  .account-page-left .logo {
    height: 32px;
  }
  .account-page-title {
    font-size: 34px;
  }
  .account-page-left .flex .accent-text-light {
    display: none;
  }
}
@media only screen and (max-width: 720px) {
  .account-page-left {
    padding: 24px;
    min-width: 304px;
    max-width: 100vw;
  }
}
@media only screen and (max-width: 560px) {
  .account-page .input-container {
    height: 56px;
  }
  .page-wrapper-mobile .account-page .input-container input {
    font-size: 16px;
  }
  .account-page-left {
    padding: 0 16px 16px;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
  }
  .account-page-title,
  .account-page-title-small {
    text-align: center;
  }
  .account-page-left > div:nth-child(2) {
    max-width: 100%;
    width: 320px;
    margin: 0 auto;
  }
  .account-page-title {
    font-size: 24px;
  }
  .account-page-left .medium-text {
    font-size: 14px;
  }
  .account-page-left .underline-text {
    font-size: 11px;
  }
  .account-page-left .flex {
    justify-content: center;
    font-size: 10px;
    margin-top: 32px;
  }
  .account-page-left .flex > * {
    margin: 0 4px;
  }
}
/*** Account Page Right Styles ***/
.account-page-right,
.account-page-right img {
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
}
.account-page-right img {
  display: block;
}
@media only screen and (max-width: 560px) {
  .account-page-right {
    display: none;
  }
}

// /***** LOGIN PAGE STYLES *****/
// /*** Google Sign In Button --> REMOVED LIB ***/
// .g-signin2 .abcRioButton {
//   width: 100% !important;
//   height: 56px !important;
//   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 0 5px -2px rgba(0,0,0,0.12), 0 2px 18px 2px rgba(0,0,0,0.08) !important;
//   border: 1px solid rgba(0, 0, 0, .04);
//   border-radius: 3px !important;
// }
// .g-signin2 .abcRioButton:hover {box-shadow: none !important; transition: box-shadow 300ms linear;}
// .g-signin2 .abcRioButtonContentWrapper {display: flex; height: 100%; align-items: center; padding-left: 40px;}
// .g-signin2 .abcRioButtonIcon {padding: 0 !important; margin-right: 16px;}
// .g-signin2 .abcRioButtonIcon > div,  .g-signin2 .abcRioButtonIcon > div svg {height: 24px !important; width: 24px !important;}
// .g-signin2 .abcRioButtonContents {font-weight: normal; color: rgba(0, 0, 0, .87); font-size: 16px !important; font-family: AvenirRoman;}
// @media only screen and (max-width: 560px){
//   .g-signin2 .abcRioButtonIcon > div,  .g-signin2 .abcRioButtonIcon > div svg {height: 18px !important; width: 18px !important;}
//   .g-signin2 .abcRioButtonContents {font-size: 12px !important;}
// }

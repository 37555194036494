.search-input {
  background: #fff;
  border-radius: 3px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  max-width: 100%;
  transition: border 300ms linear;
}
.search-input-focused {
  border: 1px solid $accent-color;
}
.search-input input {
  background: transparent;
  border: none;
  font-size: 14px;
  width: 100%;
}
.search-input input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.search-input input:focus {
  outline: none;
  border: none;
}
.autocomplete-menu {
  position: absolute;
  z-index: 3;
  margin-top: 12px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow:
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 1px 2px rgba(0, 0, 0, 0.08);
}
.autocomplete-menu > div {
  transition: background 300ms linear;
}

@media only screen and (max-width: 1040px) {
  .search-input {
    width: 340px;
  }
}

@media only screen and (max-width: 620px) {
  .search-input {
    width: 280px;
  }
}

@media only screen and (max-width: 560px) {
  .search-input {
    width: 100%;
    margin-bottom: 12px;
    padding-left: 8px;
  }
  .page-wrapper-mobile .search-input input {
    font-size: 16px;
  }
  .search-input input::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
}

html,
body,
#root {
  height: 100%;
}

/***** KINUS PAGE STYLES *****/
.kinus-page {
  padding: 40px;
  min-height: calc(100vh - 72px);
  background: #fff;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .kinus-page {
    padding: 16px 0;
  }
}
@media only screen and (max-width: 560px) {
  .kinus-page {
    padding-bottom: 56px;
  }
}

/*** Kinus Page - View Details Modal ***/
.details-modal {
  max-width: 100%;
  width: 288px;
  padding: 16px 16px 24px;
}
.details-modal p i {
  font-size: 16px;
  vertical-align: middle;
  float: left;
}
.details-modal .modal-btns {
  margin-top: 32px;
}

/*** Kinus Page - Wizard Steps Styles ***/
.kinus-page-wizard-steps {
  background: #f5f5f5;
  margin: 24px -40px 48px;
  padding: 12px 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 72px;
  z-index: 2;
}
.banner-wrapper + .header + .page-wrapper .kinus-page-wizard-steps {
  top: 120px;
}
.kinus-page-wizard-steps .container {
  display: flex;
}
.wizard-step {
  margin-right: 64px;
  display: flex;
  align-items: center;
  color: $accent-text-light;
}
.wizard-step .wizard-step-number {
  border: 1px solid $accent-text-light;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: 12px;
  position: relative;
}
.wizard-step.active .wizard-step-number {
  background: $accent-color;
  color: #fff;
  border-color: $accent-color;
}
.wizard-step.active .wizard-step-number:after {
  content: "";
  height: 32px;
  width: 32px;
  border: 1px solid $accent-color;
  border-radius: 100%;
  position: absolute;
  top: -6px;
  left: -6px;
}
.wizard-step.active .wizard-step-title,
.wizard-step.completed .wizard-step-title,
.wizard-step.error .wizard-step-title {
  color: #333;
}
.wizard-step.completed .wizard-step-title {
  cursor: pointer;
}
.wizard-step.completed .wizard-step-number {
  background: #63c674;
  color: #fff;
  border: 1px solid #63c674;
  cursor: pointer;
}
.wizard-step.completed .wizard-step-number i,
.wizard-step.error .wizard-step-number i {
  font-size: 18px;
}
.wizard-step.error .wizard-step-number {
  background: $error-color;
  color: #fff;
  border: 1px solid $error-color;
  cursor: pointer;
}
@media only screen and (max-width: 1040px) {
  .kinus-page-wizard-steps {
    top: 56px;
  }
  .wizard-step {
    margin-right: 40px;
  }
}
@media only screen and (max-width: 872px) {
  .wizard-step:not(:last-child) {
    margin-right: 24px;
  }
  .wizard-step:last-child {
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .kinus-page-wizard-steps {
    margin: 24px 0;
    padding: 12px 0;
  }
  .kinus-page-wizard-steps .container {
    justify-content: space-between;
  }
  .wizard-step-title {
    display: none;
  }
}
@media only screen and (max-width: 560px) {
  .kinus-page-wizard-steps {
    margin-top: 16px;
  }
}

/*** Kinus Page - Form Styles ***/
.kinus-page-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-self: flex-start;
  width: calc(100% - 384px);
}
.kinus-page-form-container .kinus-page-form {
  width: 100%;
}
.kinus-page-form {
  width: calc(100% - 424px);
}
.kinus-page-form .xxl-text {
  font-size: 22px;
  border-bottom: 1px solid #edecec;
  padding-bottom: 16px;
  margin-bottom: 32px;
}
.kinus-form-section:not(:last-child) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 32px;
}
.kinus-form-label {
  font-size: 12px;
  color: $accent-text;
  width: 140px;
  margin-right: 32px;
}
@media only screen and (max-width: 1040px) {
  .kinus-page-form-container {
    width: 100%;
  }
}
@media only screen and (max-width: 560px) {
  .kinus-page-form .xxl-text {
    font-size: 18px;
    margin: 0 -16px 32px;
    padding: 0 16px 16px;
  }
  .kinus-page-form .medium-text.fw-700 {
    font-size: 14px;
  }
  .kinus-form-section {
    margin: 0 -16px 32px;
    padding: 0 16px 24px;
  }
  .kinus-form-section:last-child {
    margin-bottom: 0;
  }
  .mobile-block .kinus-form-label {
    margin-bottom: 4px;
  }
  .kinus-form-section .mobile-block .radio-container {
    width: 100%;
  }
  .kinus-form-section
    .mobile-block
    .radio-container
    input[type="radio"]
    + label {
    width: 50% !important;
  }
}

/* Kinus Form - Family Table Styles */
.kinus-form-family-table {
  border: 1px solid #edecec;
  width: 100%;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 0.15fr 1fr 0.6fr 0.8fr 1fr;
}
.kinus-form-family-table-header p {
  font-size: 12px;
  color: $accent-text;
  font-family: "AvenirHeavy";
}
.kinus-form-family-table-row {
  display: contents;
}
.kinus-form-family-table-row:not(:last-child) > * {
  border-bottom: 1px solid #edecec;
}
.kinus-form-family-table-row > *:not(:first-child) {
  padding-left: 16px;
}
.kinus-form-family-table-row > *:first-child {
  padding-left: 8px;
}
.kinus-form-family-table-row > * {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}
.kinus-form-family-table-row .custom-checkbox[type="checkbox"] + label {
  top: 0;
  cursor: pointer;
}
.kinus-form-family-table-row .custom-checkbox[type="checkbox"] + label:before {
  height: 10px;
  width: 10px;
  min-width: 10px;
}
.kinus-form-family-table-row .custom-checkbox[type="checkbox"] + label:after {
  height: 7px;
  width: 2.5px;
  left: 5px;
  top: 1px;
}
.kinus-form-family-table-row.disabled
  .custom-checkbox[type="checkbox"]
  + label {
  cursor: not-allowed;
  opacity: 0.5;
}
.kinus-form-family-table-row.disabled p:first-of-type {
  color: $accent-text;
}
.kinus-form-family-table-row select {
  border: none;
  background: transparent;
  -webkit-appearance: none;
  background-image: url("/images/chevron_down.png");
  background-repeat: no-repeat;
  background-position: center right;
  font-size: 14px;
  padding-right: 24px;
  margin-right: 8px;
  max-width: 100%;
}
.kinus-form-family-table-row select:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.mobile-collapse {
  display: none;
}
@media only screen and (max-width: 1104px) {
  .kinus-form-family-table {
    grid-template-columns: 5% 23% 23% 24% 25%;
  }
}
@media only screen and (max-width: 560px) {
  .kinus-form-section.family-info:first-of-type > .flex {
    display: block;
  }
  .kinus-form-section.family-info:first-of-type > .flex .btn-accent {
    font-size: 12px;
    display: inline-block;
    margin-left: 0;
    margin-top: 12px;
    margin-bottom: 8px;
    padding: 0 16px;
  }
  .kinus-form-family-table {
    display: block;
    border-radius: 3px;
    margin-bottom: 0;
  }
  .kinus-form-family-table-header.kinus-form-family-table-row {
    display: none;
  }
  .kinus-form-family-table-row {
    display: block;
    padding-bottom: 12px;
    border-bottom: 1px solid #edecec;
    position: relative;
  }
  .kinus-form-family-table-row:last-child {
    border-bottom: none;
  }
  .kinus-form-family-table-row .flex.custom-checkbox-container {
    position: absolute;
  }
  .kinus-form-family-table-row > *:not(:first-child) {
    padding-left: 40px;
  }
  .kinus-form-family-table-row p.accent-text.uppercase-text {
    display: block;
    margin-bottom: 4px;
  }
  .kinus-form-family-table-row > * {
    display: block;
  }
  .kinus-form-family-table-row:not(:last-child) > * {
    border-bottom: none;
  }
  .kinus-form-family-table-row select {
    width: calc(100% - 8px);
  }
  .mobile-collapse {
    position: absolute;
    top: 0;
    right: 8px;
    display: block;
  }
  .mobile-collapse i {
    font-size: 18px;
    cursor: pointer;
  }
  .kinus-form-family-table-row.opened .mobile-collapse .open {
    display: none;
  }
  .kinus-form-family-table-row.closed .mobile-collapse .close {
    display: none;
  }
  .kinus-form-family-table-row.closed {
    padding-bottom: 0;
  }
  .kinus-form-family-table-row.closed div:not(:first-of-type) {
    display: none;
  }
  .kinus-form-family-table-row.closed div.mobile-collapse {
    display: block;
  }
}
/* Kinus Form - Hotel Dates Styles */
.hotel-dates {
  margin-top: 40px;
}
.hotel-date {
  display: flex;
  border: 1px solid #edecec;
  height: 80px;
  padding: 8px;
  position: relative;
  width: 20%;
}
.hotel-date.selected {
  border-color: $accent-color;
}
.hotel-date:first-child {
  border-radius: 3px 0 0 3px;
}
.hotel-date:last-child {
  border-radius: 0 3px 3px 0;
}
.hotel-date > .flex {
  justify-content: space-between;
  width: 100%;
}
.hotel-date p.small-text {
  margin-right: 24px;
  flex: 1;
}
.hotel-date .radio-button-container {
  width: 16px;
}
.hotel-date .radio-button-container [type="checkbox"]:checked + label:before,
.hotel-date
  .radio-button-container
  [type="checkbox"]:not(:checked)
  + label:before {
  height: 14px;
  width: 14px;
}
.hotel-date .radio-button-container [type="checkbox"]:checked + label:before {
  border-color: $accent-color;
}
.hotel-date .radio-button-container [type="checkbox"]:checked + label:after,
.hotel-date
  .radio-button-container
  [type="checkbox"]:not(:checked)
  + label:after {
  background: $accent-color;
  height: 8px;
  width: 8px;
}
.kinus-banner {
  background: $accent-color;
  color: #fff;
  z-index: 1;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  padding: 3px;
  position: absolute;
  bottom: 32px;
  left: 20%;
  width: calc(60% - 16px);
  margin-left: 8px;
}
.kinus-banner-mobile {
  display: none;
}
@media only screen and (max-width: 560px) {
  .kinus-banner {
    display: none;
  }
  .hotel-dates {
    display: block;
  }
  .hotel-date {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 8px;
    border-radius: 3px;
  }
  .hotel-date .flex {
    flex-direction: row-reverse;
    align-items: center;
  }
  .hotel-date .flex p {
    margin-left: 8px;
  }
  .kinus-banner-mobile {
    background: $accent-color;
    color: #fff;
    border-radius: 3px;
    padding: 2px 16px;
    font-size: 12px;
    display: block;
  }
  .hotel-date:first-child .kinus-banner-mobile,
  .hotel-date:last-of-type .kinus-banner-mobile {
    display: none;
  }
  .hotel-date .radio-button-container [type="checkbox"]:checked + label,
  .radio-button-container [type="checkbox"]:not(:checked) + label {
    top: -8px;
  }
}
/* Kinus Form - Hotel Rooms Styles */
.hotel-rooms .disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.connecting-rooms-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
@media only screen and (max-width: 560px) {
  .hotel-rooms .flex {
    display: block;
  }
  .connecting-rooms-checkbox {
    margin-left: 0;
    margin-top: 8px;
  }
  .connecting-rooms-checkbox .custom-checkbox[type="checkbox"] + label {
    align-items: center;
  }
}

/* Kinus Form - Extras Styles */
.extras-row:not(:last-child) {
  margin-bottom: 12px;
}
.extras-row p.small-text {
  width: 160px;
}
.extras-row .custom-select {
  border: 1px solid #edecec;
  background: transparent;
  height: 32px;
  width: 120px;
  border-radius: 3px;
  font-size: 14px;
}
/* Kinus Form - Transportation Styles */
.transportation-row {
  @extend .extras-row;
}
@media only screen and (max-width: 560px) {
  .transportation-row {
    display: block;
  }
  .transportation-row > .accent-text {
    margin-bottom: 8px;
  }
}

/* Kinus Form - Child Seating Styles */
.child-seat {
  margin-bottom: 12px;
}
.child-seat p.small-text {
  width: 136px;
  margin-right: 24px;
}
@media only screen and (max-width: 560px) {
  .seating-preferences .radio-container {
    width: 100%;
  }
  .seating-preferences .radio-container input[type="radio"] + label {
    width: 50% !important;
  }
  .child-seat {
    display: block;
  }
  .child-seat p.small-text {
    width: 100%;
    margin-bottom: 4px;
  }
}

/* Kinus Form - Children Styles */
.radio-container input:nth-child(5) + label,
.radio-container input:nth-child(7) + label,
.radio-container input:nth-child(9) + label {
  border-left: 1px solid #e0e0e0;
}
.child .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
.child textarea {
  border: 1px solid #edecec;
  border-radius: 3px;
  height: 80px;
  width: 100%;
  resize: none;
  padding: 12px;
  transition: border 300ms linear;
}
.child textarea:focus {
  border: 1px solid $accent-color;
  outline: none;
}
.radio-button-container input:disabled,
.radio-button-container label.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.radio-button-container.error label {
  color: $error-color;
}
.custom-checkbox-container.error label {
  color: $error-color;
}
.custom-checkbox-container.disabled label {
  cursor: not-allowed;
  opacity: 0.5;
}
@media only screen and (max-width: 560px) {
  .parents-phone-numbers .flex {
    display: block;
  }
  .parents-phone-numbers .flex p {
    float: left;
    width: auto !important;
    margin-right: 16px;
  }
  .parents-phone-numbers input {
    clear: both;
    width: 100%;
    margin-top: 4px;
    margin-left: 0;
  }
  .child .radio-button-container [type="checkbox"]:checked + label,
  .child .radio-button-container [type="checkbox"]:not(:checked) + label {
    top: 0;
  }
}

/* Kinus Form - Confirm Styles */
.confirm {
  @extend .child;
}
.terms {
  border: 1px solid #edecec;
  border-radius: 3px;
  max-height: 160px;
  width: 100%;
  resize: none;
  padding: 16px;
  background: #fafafa;
  overflow: auto;
}
.terms p {
  line-height: 1.5;
  margin-bottom: 16px;
}
/* Kinus Form - Payment Styles */
.installments div {
  border: 1px solid #edecec;
  border-radius: 3px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 56px;
}
.cc-info-form .custom-input {
  width: 336px;
  max-width: 100%;
  display: grid;
  align-items: center;
}
.cc-info-form .custom-select {
  border: 1px solid #edecec;
  background: transparent;
  height: 32px;
  width: 116px;
  border-radius: 3px;
  font-size: 14px;
}
.cc-info-form .custom-input.StripeElement--empty {
  opacity: 0.6 !important;
}
.cc-info-form .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
.billing-address-form .custom-input {
  width: 336px;
}
.kinus-page .billing-address-form .custom-input:disabled {
  opacity: 1;
}
.billing-address-form .custom-select,
.billing-address-form .custom-select > select {
  border: 1px solid #edecec;
  background: transparent;
  height: 32px;
  width: 336px;
  max-width: 100%;
  border-radius: 3px;
  font-size: 14px;
  position: relative;
  padding-left: 4px;
  color: #555;
}
.billing-address-form .custom-select > select:focus {
  border: none;
  outline: none;
}
.billing-address-form .custom-select > div {
  border: none;
  padding: 0;
  height: auto;
}
.billing-address-form .custom-select.error {
  border: 1px solid $error-color;
}
.billing-address-form .custom-select > select {
  border: none;
  width: calc(100% + 8px);
}
// .billing-address-form .custom-select > select:disabled {opacity: .5; cursor: not-allowed; -webkit-appearance: none;}
.billing-address-section
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
@media only screen and (max-width: 560px) {
  .payment-options .radio-button-container .flex:first-child {
    display: block;
  }
  .installments {
    margin-left: 32px;
  }
  .cc-info-form .custom-checkbox-container {
    margin-bottom: 8px;
  }
  .cc-info-form .custom-select,
  .cc-info-form .custom-input {
    width: 100%;
  }
  .billing-address-form .custom-select,
  .billing-address-form .custom-input {
    width: 100%;
  }
}

/*** Kinus Page - Wizard Button Styles ***/
.wizard-btns {
  position: relative;
}
.wizard-btns .btn {
  width: 96px;
}
.wizard-btns .error-message {
  top: 100%;
  right: 0;
  margin-top: 8px;
}
.prev-last-step {
  display: none;
}
@media only screen and (max-width: 560px) {
  .kinus-page-form-container.last-step .wizard-btns {
    position: absolute;
    bottom: -32px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
  }
  .kinus-page-form-container.last-step .wizard-btns .btn {
    display: none;
  }
  .kinus-page-form-container.last-step .prev-last-step {
    display: block;
    text-decoration: underline;
  }
}

/*** Kinus Page - Billing Summary Styles ***/
.billing-summary {
  width: 384px;
  max-width: 100%;
  margin-left: 48px;
  position: sticky;
  top: 160px;
}
.billing-summary-top-section {
  border-bottom: 1px solid #edecec;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.billing-summary-row {
  display: grid;
  grid-template-columns: 136px calc(20% - 48px) calc(40% - 60px) calc(
      40% - 75px
    );
  grid-gap: 16px;
  margin-bottom: 16px;
}
.billing-summary-row p:nth-child(2) {
  text-align: center;
}
.billing-summary-row p:nth-child(3),
.billing-summary-row p:nth-child(4) {
  text-align: right;
}
.kinus-btn-loader svg#loading {
  height: 24px;
  margin-top: 24px;
}
@media only screen and (max-width: 1072px) {
  .billing-summary {
    width: 352px;
  }
}
@media only screen and (max-width: 1040px) {
  .kinus-page-form-container + div .billing-summary {
    display: none;
  }
  .kinus-page-form-container.last-step + div .billing-summary {
    display: block;
  }
  .kinus-page-form-container.last-step + div {
    border-top: 1px solid #edecec;
    margin: 0 -16px;
    padding: 0 16px;
  }
  .billing-summary {
    display: block;
    margin-left: 0;
    box-shadow: none;
    border: none;
    padding: 24px 0 0;
    width: calc(100% + 80px);
  }
}

/*** Kinus Page - Registration States Styles ***/
.kinus-page-form-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: calc(100vh - 200px);
  padding-bottom: 72px;
}

/***** MODAL STYLES *****/
.ReactModal__Overlay--after-open {
  z-index: 100000; // z-index for react-modal lib should match the z-index specified for modal-container below
}
.modal-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.12);
  top: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
}
.modal {
  max-height: 80vh;
  overflow: auto;
}
.modal-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}
.modal-btns svg#loading {
  margin-left: auto;
  margin-right: 0;
}
.modal-btns-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  grid-gap: 12px;
  .MuiButton-root {
    font-family: "AvenirMedium";
    padding: 6px 12px;
  }
}
.modal-separator {
  border: 0.5px solid #edecec;
  margin: 24px -32px -16px;
}
@media only screen and (max-width: 560px) {
  .full-page-mobile-modal-container {
    padding: 0;
  }
  .full-page-mobile-modal-container .modal-card.card {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: unset;
  }
  .modal-mobile-header {
    padding: 0 24px 24px;
    border-bottom: 1px solid #edecec;
    margin: 0 -24px 24px;
  }
  .modal-mobile-header + .large-text.fw-500 {
    font-family: AvenirHeavy;
    font-size: 20px;
    margin-bottom: 24px;
  }
  .full-page-mobile-modal-container .modal-btns .btn {
    width: 50%;
    margin-top: 16px;
  }
  .full-page-mobile-modal-container .modal-btns .btn:first-of-type {
    background: #edecec;
    border: 1px solid #edecec;
    color: rgba(0, 0, 0, 0.87);
  }
  .full-page-mobile-modal-container .modal-btns .btn:first-of-type:hover {
    color: #edecec;
    background: rgba(0, 0, 0, 0.87);
    opacity: 1;
  }
  .full-page-mobile-modal-container .modal-btns .btn:last-of-type {
    background: $accent-color;
    border: 1px solid $accent-color;
    color: #fff;
  }
  .full-page-mobile-modal-container .modal-btns .btn:last-of-type:hover {
    background: #fff;
    color: $accent-color;
    opacity: 1;
  }
  .modal-btns-wrapper {
    .MuiButton-root {
      font-size: 12px;
    }
  }
}
/*** Modal Sizes Styles ***/
.small-modal {
  width: 440px;
  max-width: 96%;
  max-height: 96vh;
  overflow: auto;
}
.large-modal {
  width: 640px;
  max-width: 96%;
  max-height: 96vh;
  overflow: auto;
}
.xl-modal {
  width: 760px;
  max-width: 96%;
  max-height: 96vh;
  overflow: auto;
}
.small-modal-overflow {
  @extend .small-modal;
  overflow: visible; // allow a drop-down at the bottom of the modal to extend over the edge of the modal instead of creating a scroll within the modal
}
/*** Transition Styles ***/
.example-enter {
  opacity: 0.01;
}
.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.example-leave {
  opacity: 1;
}
.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

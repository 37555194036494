* {
  box-sizing: border-box;
}

/***** PAGE WRAPPER STYLES *****/
.page-wrapper {
  padding-top: 72px;
  background: #fff;
  min-height: 100vh;
}
@media only screen and (max-width: 1040px) {
  .page-wrapper {
    padding-top: 56px;
  }
}

/***** CONTAINER STYLES *****/
.container {
  background: #fff;
  margin: 0 auto;
  padding: 0 40px;
}
.page.container {
  padding: 40px;
}
@media only screen and (max-width: 1320px) {
  .page.container {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 1040px) {
  .container {
    padding: 0 24px;
    max-width: 848px;
  }
  .page.container {
    padding: 40px 24px 88px;
  }
}
@media only screen and (max-width: 560px) {
  .container {
    padding: 0 16px;
  }
  .page.container {
    padding: 24px 16px 96px;
  }
}

/***** DISPLAY STYLES *****/
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.display-contents {
  display: contents;
}

/***** POSITION STYLES *****/
.relative {
  position: relative;
}

/***** WIDTH STYLES *****/
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}

.filter-width {
  width: 204px;
}
@media only screen and (max-width: 560px) {
  .mobile-full-width {
    width: 100%;
  }
}

/***** HEIGHT STYLES *****/
.full-height {
  height: 100%;
}
@media only screen and (max-width: 560px) {
  .mobile-height-auto {
    height: auto;
  }
}

/***** FLEX STYLES *****/
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-align-center {
  align-items: center;
}
.flex-align-top {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-space {
  justify-content: space-between;
}
.flex-justify-center {
  justify-content: center;
}

@media only screen and (max-width: 1040px) {
  .flex.tablet-block {
    display: block;
  }
  .tablet-flex {
    display: flex;
  }
}
@media only screen and (max-width: 560px) {
  .mobile-flex-justify-space {
    justify-content: space-between;
  }
  .mobile-flex-justify-start {
    justify-content: flex-start;
  }
  .mobile-flex-justify-end {
    justify-content: flex-end;
  }
  .mobile-flex-align-center {
    align-items: center;
  }
  .mobile-flex-align-top {
    align-items: flex-start;
  }
  .mobile-flex-align-end {
    align-items: flex-end;
  }
  .flex.mobile-block {
    display: block;
  }
  .mobile-flex {
    display: flex;
  }
  .mobile-flex-column {
    flex-direction: column;
  }
}

/***** GRID STYLES *****/
.align-self-center {
  align-self: center;
}
/*** Two Column Grid ***/
.two-column-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
/*** Three Column Grid ***/
.three-column-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}
/*** Responsive Styles ***/
@media only screen and (max-width: 560px) {
  .mobile-one-column-grid {
    grid-template-columns: 1fr;
  }
}

/***** OVERFLOW STYLES *****/
.overflow-hidden {
  overflow: hidden;
}
/***** WHITESPACE STYLES *****/
.pre-wrap {
  white-space: pre-wrap;
}
.no-wrap {
  white-space: nowrap;
}
.pre {
  white-space: pre;
}
.whitespace-normal {
  white-space: normal;
}

/***** MARGIN STYLES *****/
.mb-96 {
  margin-bottom: 96px;
}
.mb-64 {
  margin-bottom: 64px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mt-48 {
  margin-top: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mb-28 {
  margin-bottom: 28px;
}
.ml-28 {
  margin-left: 28px;
}
.mt-28 {
  margin-top: 28px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mr-18 {
  margin-right: 18px;
}
.mt-16 {
  margin-top: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-6 {
  margin-right: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.mt-4 {
  margin-top: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-2 {
  margin-right: 2px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
@media only screen and (max-width: 1040px) {
  .tablet-mb-24 {
    margin-bottom: 24px;
  }
  .tablet-mr-16 {
    margin-right: 16px;
  }
  .tablet-mt-8 {
    margin-top: 8px;
  }
  .tablet-mr-8 {
    margin-right: 8px;
  }
  .tablet-mb-8 {
    margin-bottom: 8px;
  }
  .tablet-ml-8 {
    margin-left: 8px;
  }
  .tablet-mt-0 {
    margin-top: 0;
  }
  .tablet-mr-0 {
    margin-right: 0;
  }
  .tablet-mb-0 {
    margin-bottom: 0;
  }
  .tablet-ml-0 {
    margin-left: 0;
  }
}
@media only screen and (max-width: 560px) {
  .mobile-mt-48 {
    margin-top: 48px;
  }
  .mobile-mt-24 {
    margin-top: 24px;
  }
  .mobile-mt-16 {
    margin-top: 16px;
  }
  .mobile-mr-16 {
    margin-right: 16px;
  }
  .mobile-mb-16 {
    margin-bottom: 16px;
  }
  .mobile-ml-16 {
    margin-left: 16px;
  }
  .mobile-mt-8 {
    margin-top: 8px;
  }
  .mobile-mb-8 {
    margin-bottom: 8px;
  }
  .mobile-mr-4 {
    margin-right: 4px;
  }
  .mobile-mb-4 {
    margin-bottom: 4px;
  }
  .mobile-ml-4 {
    margin-left: 4px;
  }
  .mobile-mt-0 {
    margin-top: 0;
  }
  .mobile-mr-0 {
    margin-right: 0;
  }
  .mobile-mb-0 {
    margin-bottom: 0;
  }
  .mobile-ml-0 {
    margin-left: 0;
  }
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1041px) {
  .desktop-hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .tablet-hidden {
    display: none !important;
  }
  .tablet-visible {
    display: block;
  }
}
@media only screen and (max-width: 560px) {
  .mobile-hidden {
    display: none !important;
  }
  .mobile-showing {
    display: block;
  }
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

/***** LAMPLIGHTERS PAGE STYLES *****/
.lamplighters-page {
  @extend .trip-page;
  min-height: calc(100vh - 72px) !important;
}
.lamplighters-card {
  @extend .trip-card;
  max-width: calc(100% - 150px) !important;
  height: 100%;
}
@media only screen and (max-width: 1040px) {
  // can't extend @trip-card here so have to copy all the settings
  .lamplighters-card {
    max-width: 100% !important;
    box-shadow: none;
    border-radius: 0;
    padding: 24px 16px;
    border: none;
    border-top: 1px solid #edecec;
  }
}

/***** LAMPLIGHTERS DASHBOARD STYLES *****/
/*** Lamplighters Dashboard Timeline Styles ***/
.lamplighters-deadline-point-circle {
  height: 24px;
  width: 24px;
  border: 2px solid #dcdde8;
  background: #fff;
  display: block;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto 16px;
  z-index: 1;
  position: relative;
}
.lamplighters-deadline-point:after {
  content: "";
  border: 1px dashed #dcdde8;
  width: 100%;
  position: absolute;
  top: 12px;
  left: 0;
}
.lamplighters-schedule-widget-wrapper {
  padding: 0;
}
.lamplighters-schedule-widget-card {
  padding: 24px;
  margin-top: 32px;
}
.lamplighters-schedule-widget-card label {
  color: rgba(0, 0, 0, 0.48);
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
  font-weight: 500;
}
.lamplighters-log-interaction-btn {
  margin-left: 16px;
  padding: 0px 24px;
}
.lamplighters-schedule-info {
  font-size: 16px;
  line-height: 2;
}
.lamplighters-deadline-point:nth-child(1):after {
  left: 50%;
  width: 50%;
}
.lamplighters-deadline-point:last-child:after {
  width: 50%;
}
/* Lamplighters Dashboard Timeline Completed Styles */
.lamplighters-deadline-point.completed .lamplighters-deadline-point-circle {
  border-color: $accent-color;
  background: $accent-color;
}
.lamplighters-deadline-point-circle.completed {
  border-color: $accent-color;
  background: $accent-color;
}
.lamplighters-deadline-point.completed:after {
  border: 1px solid $accent-color;
}
.lamplighters-deadline-point.completed.final-completed:after {
  width: 50%;
}
.lamplighters-deadline-point.completed.final-completed:not(
    :nth-child(1)
  ):before {
  content: "";
  border: 1px dashed #dcdde8;
  width: 100%;
  position: absolute;
  top: 12px;
  left: 0;
}
/*** Lamplighters Dashboard Content Styles ***/
.total-text {
  color: #1e1c1c;
}
.active-grant-text {
  color: #2774ae;
}
.flagged-grant-text {
  color: #ff6b6b;
}
.flagged-grant-text-2 {
  color: #f3893d;
}
.flagged-grant-text-3 {
  color: #fec609;
}
.submitted-grant-text {
  color: #4fcec5;
}
.lamplighters-allocation-card {
  width: 100%;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 16px;
}
.lamplighters-allocation-card .two-column-grid {
  grid-template-columns: 48px 1fr;
}
.allocation-wrapper {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.allocation-grouping {
  display: flex;
  gap: 8px;
}
.allocation-left-column {
  display: flex;
  align-items: center;
  width: auto;
}
.allocation-quantity {
  font-size: 14px;
  font-family: "Archivo Black";
  margin-right: 6px;
}
.circle-icon {
  font-size: 8px;
  display: flex;
  align-self: center;
}
.lamplighters-payments-card {
  @extend .lamplighters-allocation-card;
  height: unset;
  max-width: 200px;
  text-align: left;
}
.lamplighters-goal-card {
  background-color: #f4f4f5;
  font-size: 13px;
  padding: 16px;
  margin-top: 16px;
  border-radius: 3px;
  max-width: 200px;
}
.allocation-section {
  display: flex;
  gap: 4px;
}
@media only screen and (max-width: 560px) {
  .lamplighters-schedule-widget-wrapper {
    padding: 24px 16px;
    box-shadow:
      0 2px 5px -2px rgba(0, 0, 0, 0.12),
      0 2px 18px 2px rgba(0, 0, 0, 0.08);
  }
  .lamplighters-schedule-widget-card {
    padding: 24px;
    margin-top: 0px;
  }
  .lamplighters-schedule-info {
    font-size: 20px;
    max-width: 200px;
    line-height: 1.25;
  }
  .lamplighters-log-interaction-btn {
    max-width: 150px;
    padding: 0px 14px;
    font-size: 13px;
  }
  .lamplighters-payments-card {
    max-width: 100%;
    margin-top: 24px;
  }
  .allocation-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .allocation-grouping {
    flex-direction: column;
    width: 100%;
  }
  .allocation-section {
    justify-content: space-between;
    margin-right: 48px;
  }
  .allocation-quantity {
    width: 32px;
    padding-right: 12px;
    text-align: end;
  }
  .mobile-flagged-quantity {
    width: auto;
    padding-right: 0px;
  }
  .circle-icon {
    display: none;
  }
  .allocation-left-column {
    min-width: 160px;
  }
  .lamplighters-goal-card {
    max-width: 100%;
  }
  .allocation-total-mobile {
    justify-content: space-between;
    margin-right: 48px;
  }
}

/***** LAMPLIGHTERS STUDENTS STYLES *****/
/*** Lamplighters Students List Styles ***/
.lamplighters-students-table {
  overflow: auto;
  display: grid;
  height: calc(100% - 200px);
}
.lamplighters-students-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
.lamplighters-students-checkbox-container button {
  align-items: center;
}
@media only screen and (max-width: 560px) {
  .lamplighters-students-checkbox-container {
    justify-content: start;
    margin-top: 8px;
  }
}
.lamplighters-students-table-row.paginated-table-header-row .tooltip {
  left: -280px;
  width: 380px;
  white-space: pre-wrap;
}
.lamplighters-students-table-row.students-table-row {
  background: transparent;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
}
.lamplighters-students-table-row .student-grant-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: keep-all;
}
.lamplighters-students-table-row .student-grant-container .activate-link {
  font-size: 13px;
  font-weight: 700;
}
.lamplighters-grant-column-checkbox-list {
  margin: 8px 0px;
}
.lamplighters-grant-column-checkbox {
  @extend .lamplighters-students-checkbox;
  padding: 12px;
}
.lamplighters-grant-column-checkbox:hover {
  background-color: #f5f5f5;
}
@media (max-width: 1500px) {
  .filter-flex {
    display: block;
  }
}
.lamplighters-filter-select {
  @extend .filter-select;
}
.lamplighters-filter-select > .lamplighters-filter-select__control {
  border: none;
  background: transparent;
  cursor: pointer;
}
.lamplighters-filter-select__single-value {
  color: $accent-color !important;
  position: static !important;
  transform: none !important;
  max-width: none !important;
}
.lamplighters-filter-select__indicator-separator {
  display: none;
}
.lamplighters-filter-select__dropdown-indicator {
  color: $accent-color !important;
}
.lamplighters-filter-select__placeholder {
  color: $accent-color !important;
  position: static !important;
  transform: none !important;
  max-width: none !important;
}
.lamplighters-filter-select__indicator {
  margin-left: -14px;
}
.lamplighters-filter-select__menu {
  min-width: 180px;
}
.lamplighters-search-input {
  width: 280px;
}
.lamplighters-students-filters {
  flex-flow: wrap;
  position: relative;
  z-index: 2;
  line-height: normal;
}
.lamplighters-students-filters .search-input-container {
  width: 100%;
}
.lamplighters-search-input-container .autocomplete-menu {
  max-height: 200px;
  overflow: auto;
  position: absolute;
  margin-left: -17px;
  margin-top: 15px;
  width: 280px;
}
@media only screen and (max-width: 1144px) {
  .lamplighters-students-filters.flex {
    display: block;
  }
  .lamplighters-search-input-container {
    max-width: 100%;
  }
}
.lamplighters-custom-input {
  @extend .custom-input;
  margin: 10px;
  display: block;
  width: 120px;
  height: 40px;
}
.lamplighters-custom-input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.lamplighters-age-picker {
  border-radius: 5px;
  padding: 8px;
  border: 1px solid white;
}
.lamplighters-age-picker-border {
  border: 1px solid rgb(38, 132, 255);
}
@media only screen and (max-width: 560px) {
  .lamplighters-students-filters .search-input-container {
    max-width: 100%;
  }
  .lamplighters-students-table-row.students-table-row {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    padding: 8px 32px;
    width: calc(100% + 16px);
  }
}
/*** Student grant cards Styles ***/
.grant-card {
  padding: 8px;
  word-break: keep-all;
  box-shadow: none;
  border-radius: 3px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: 150px;
}

.lamplighters-students-table-row .grant-card {
  display: flex;
  flex-direction: column;
  height: 150px;
}
.lamplighters-students-table-row .grant-card .accent-text {
  height: 80px;
}
.lamplighters-students-table-row .active-grant-card,
.lamplighters-students-table-row .flagged-grant-card {
  justify-content: space-between;
}
.lamplighters-students-table-row .grant-card .submitted-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.grant-card .ineligible-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.lamplighters-students-table-row .fixed-column {
  position: sticky;
  left: 0;
  background-color: white;
  height: 100%;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #e6e6e9;
  z-index: 1;
  padding-right: 16px;
  margin-right: -16px;
}
.grant-status-tag {
  text-align: right;
}
.active-grant-card {
  border: 1px solid #2774ae;
}
.flagged-grant-card.eligibility-flag {
  border: 1px solid #ff6b6b;
}
.flagged-grant-card.missing-data-flag {
  border: 1px solid #f3893d;
}
.flagged-grant-card.duplicate-flag,
.flagged-grant-card.time-range-flag {
  border: 1px solid #ffc602;
}
.flagged-grant-card .flag-icon.eligibility-flag {
  color: #ff6b6b;
}
.flagged-grant-card .flag-icon.missing-data-flag {
  color: #f3893d;
}
.flagged-grant-card .flag-icon.duplicate-flag {
  color: #ffc602;
}
.ineligible-grant-card {
  border: 1px solid #e6e6e9 !important;
  color: #757679;
  justify-content: center;
  display: flex;
  align-items: center;
}
.inactive-grant-card {
  border: none !important;
  background-color: #f4f4f5 !important;
}
.yellow-filled {
  color: #ffc602;
}
.blue-filled {
  color: #2774ae;
}
.centered-card {
  height: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 16px;
}
.grant-card-separator {
  height: 1px;
  background-color: #e6e6e9;
  margin-left: -8px;
}
.card-action-button {
  box-sizing: border-box;
  height: 27px;
  width: 27px;
  border: 1px solid #e6e6e9;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
}
.submitted-grant-card {
  color: #33b7ae;
  border: 1px solid #4fcec5 !important;
}
.potential-grant-card {
  border-left: 4px solid #f3893d;
}
.potential-grant-card .grant-status-tag {
  color: #f3893d;
}
.grant-card .btn {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}
.grant-card .btn-activate {
  background: #f3893d;
  color: #fff;
  border: 1px solid #f3893d;
}
.grant-card .btn-activate:hover {
  background: #fff;
  color: #f3893d;
}
.grant-card .italic-text p {
  margin: 4px 0;
}
.grant-card .tooltip {
  z-index: 10;
}

@media only screen and (max-width: 560px) {
  .card.grant-card {
    box-shadow: none;
  }
}

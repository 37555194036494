/***** LOADER STYLES *****/
$boxWidth: 33px;
$animationDuration: 10s;
$animationStepDuration: calc($animationDuration / 7);

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  2% {
    transform: translate($boxWidth, 0);
  }
  12.5% {
    transform: translate($boxWidth, 0);
  }
  15.5% {
    transform: translate(2 * $boxWidth, 0);
  }
  25% {
    transform: translate(2 * $boxWidth, 0);
  }
  27% {
    transform: translate(2 * $boxWidth, $boxWidth);
  }
  37.5% {
    transform: translate(2 * $boxWidth, $boxWidth);
  }
  39.5% {
    transform: translate($boxWidth, $boxWidth);
  }
  50% {
    transform: translate($boxWidth, $boxWidth);
  }
  52% {
    transform: translate($boxWidth, 2 * $boxWidth);
  }
  62.5% {
    transform: translate($boxWidth, 2 * $boxWidth);
  }
  64.5% {
    transform: translate(0, 2 * $boxWidth);
  }
  75% {
    transform: translate(0, 2 * $boxWidth);
  }
  77% {
    transform: translate(0, $boxWidth);
  }
  87.5% {
    transform: translate(0, $boxWidth);
  }
  89.5% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

svg#loading {
  display: block;
  margin: auto;
  width: 96px;
  height: 96px;
  .rect {
    animation: slide $animationDuration ease infinite;
  }
  #rect1 {
    animation-delay: -0 * $animationStepDuration;
  }
  #rect2 {
    animation-delay: -1 * $animationStepDuration;
  }
  #rect3 {
    animation-delay: -2 * $animationStepDuration;
  }
  #rect4 {
    animation-delay: -3 * $animationStepDuration;
  }
  #rect5 {
    animation-delay: -4 * $animationStepDuration;
  }
  #rect6 {
    animation-delay: -5 * $animationStepDuration;
  }
  #rect7 {
    animation-delay: -6 * $animationStepDuration;
  }
}

/***** LOADER CONTAINER STYLES *****/
/*** Full Screen Loader ***/
.full-page-loader {
  padding-top: 28vh;
}
.overlay-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  svg {
    margin-top: 28vh !important;
  }
}
/*** Mobile Loader ***/
.mobile-loader {
  text-align: center;
  margin: 8px 0 16px 0;
}
.mobile-loader svg#loading {
  height: 24px;
  width: 24px;
}

/***** LOADER SIZES *****/
.xs-loader svg#loading {
  height: 24px;
  width: 24px;
}
.btn-loader svg#loading {
  height: 40px;
  width: 40px;
}
.btn-large-loader svg#loading {
  height: 48px;
  width: 48px;
}
.medium-loader svg#loading {
  height: 64px;
  width: 64px;
}

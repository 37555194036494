/***** VARIABLES *****/
$accent-text-light: rgba(0, 0, 0, 0.16);
$accent-text: rgba(0, 0, 0, 0.48);
$accent-text-dark: rgba(0, 0, 0, 0.6);
$accent-text-medium: #524e4e;
$secondary-text: #747579;
$accent-color: #2774ae;
$accent-color-dark: #0c425d;
$accent-color-secondary: #f3893d;
$secondary-color: #ffc602;
$error-color: #e94746;

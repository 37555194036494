.merge-duplicates-card {
  border-radius: 3px;
  background-color: #ffffff !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 5px 24px 24px 24px;
}

.merge-duplicates-btns {
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
  align-content: center;
  margin-top: 1.5%;
}

.merge-duplicates-btns .btn {
  min-width: 80px;
}

.merge-duplicates-btns .cancel-btn {
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border: none;
}

.merge-btn {
  font-size: 14px;
  border: none;
  color: #ffffff;
  background-color: #007ab5;
  border-radius: 3px;
}

/** Merge Duplicates Form **/

.merge-duplicates-form .field-row {
  display: grid;
  grid-gap: 16px 12px;
}

.exclude-student {
  position: relative;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 14px;
  justify-content: space-between;
  color: #555555;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.merge-duplicates-form input[type="checkbox"] {
  margin-right: 10px;
  width: 16;
  height: 16;
}
.merge-duplicates-form-footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  height: 5%;
  padding-bottom: 5%;
}
.merge-duplicates-form-footer .error-text {
  text-align: right;
  margin-right: 10%;
  margin-bottom: -16px;
  margin-left: 300px;
}

/** Suggestions **/
.merge-suggestions .form-header {
  margin-bottom: 30px;
}

.merge-suggestions .form-header .merge-btn {
  float: right;
  padding: 10px 28px;
  margin-top: -16px;
}

.merge-suggestions .form-header .student-tag {
  background: rgb(238, 238, 238);
  width: fit-content;
  padding: 4px 6px;
  border-radius: 4px;
}

.merge-suggestions .title {
  color: #555555;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
}

.merge-suggestions-table-header {
  display: grid;
  grid-template-columns: 1.15fr 1.5fr 1.5fr 330px;
  border-top: 3px solid #eff7fb;
  border-bottom: 3px solid #eff7fb;
  padding-left: 20px;
  margin-left: 10px;
}

.merge-suggestions-table-header p {
  padding: 14px !important;
  border-bottom: none !important;
  color: #555555;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.merge-suggestion-card {
  display: grid;
  grid-template-columns: auto 262px;
  box-sizing: border-box;
  height: 124px;
  border: 1px solid #edecec;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow:
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  padding: 32px 38px;
  margin: 10px auto;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  align-items: center;
}

.merge-suggestions .btn {
  font-size: 12px;
  letter-spacing: 0.43px;
  line-height: 16px;
  padding: 12px 16px;
}

.merge-suggestions-btns .btn {
  height: 40px;
}

.merge-suggestions-btns .merge-btn {
  margin-right: 10px;
}
.merge-duplicates-tooltip-container {
  height: auto !important;
}
.merge-duplicates-tooltip-container .tooltip {
  left: -100px;
  width: 290px;
  white-space: pre-wrap;
}
.merge-duplicates-row {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 10px;
  color: rgba(85, 85, 85, 0.5);
  align-items: center;
  height: -webkit-fill-available;
}
.merge-duplicates-warning {
  @extend .merge-duplicates-row;
  color: unset;
  font-size: 13px;
  line-height: 1.2;
  margin-left: 192px;
}
.merge-duplicates-warning i {
  color: #eed202;
  font-size: 16px;
}
.merge-duplicates-account-details {
  color: #555555;
  line-height: 25px;
}
.section-divider {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
}

.merge-radio-container input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  outline: none;
  border: 1px solid gray;
}

.merge-radio-container input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.merge-radio-container input[type="radio"]:checked:before {
  background: rgb(51, 183, 174);
}

.merge-radio-container input[type="radio"]:checked {
  border-color: rgb(51, 183, 174);
}

/***** HEADER STYLES *****/
.header {
  position: fixed;
  width: 100vw;
  background: #fff;
  height: 72px;
  box-shadow:
    0 2px 5px -3px rgba(0, 0, 0, 0.08),
    0 2px 16px -2px rgba(0, 0, 0, 0.08);
  z-index: 1000;
}
.header .container:not(.modal-container *) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 100%;
  max-width: 100%;
}
@media only screen and (max-width: 1040px) {
  .header {
    height: 56px;
  }
}
@media only screen and (max-width: 560px) {
  .header {
    z-index: 200;
    box-shadow: none;
  }
  .header .container:not(.modal-container *) {
    grid-template-columns: repeat(2, auto);
  }
  .header.shadow {
    box-shadow:
      0 2px 5px -2px rgba(0, 0, 0, 0.12),
      0 2px 18px 2px rgba(0, 0, 0, 0.08);
  }
}
@media only screen and (max-width: 648px) {
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*** Nav Styles ***/
nav {
  height: 100%;
  justify-self: center;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.nav-link > a {
  display: flex;
  align-items: center;
  margin: 0 16px;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  height: 100%;
  font-family: AvenirHeavy;
  white-space: nowrap;
  transition: opacity 300ms linear;
}
.nav-link > .shortcut-link {
  margin: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.nav-link.no-hover > a {
  pointer-events: none;
}
.nav-link:not(.no-hover) a:not(.active-nav-link):hover {
  opacity: 0.5;
}
a.active-nav-link {
  color: #2774ae;
  border-bottom: 2px solid #2774ae;
  cursor: default;
}
a.active-nav-link.refresh {
  cursor: pointer;
}

.program-dropdown-container:hover .program-dropdown-box {
  opacity: 1;
  visibility: visible;
}
.program-dropdown-box {
  width: 320px;
  top: 56px;
}
.program-dropdown-box {
  width: 320px;
  top: 56px;
}
.program-dropdown-box.engagement {
  width: auto;
  padding: 16px;
}
.program-dropdown-box a,
.program-dropdown-box span {
  font-size: 12px;
  display: block;
  margin-bottom: 16px;
}
.program-dropdown-box a:last-child,
.program-dropdown-box span:last-child {
  margin-bottom: 0;
}
.program-dropdown-box a.link-text {
  padding-bottom: 8px;
  border-bottom: 1px solid #edecec;
  display: block;
}
.program-dropdown-box a.link-text.active-nav-link:hover {
  opacity: 1;
}
.program-dropdown-box a.link-text-secondary.active-nav-link {
  border-bottom: none;
}

.program-dropdown-box a.engagement-menu-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.program-dropdown-box a.engagement-menu-link.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.lock-icon {
  font-size: 12px;
  margin-right: 4px;
}

@media only screen and (max-width: 768px) {
  .program-dropdown-box {
    width: 264px;
  }
  .nav-link > a,
  .nav-link > .shortcut-link {
    margin: 0 8px;
  }
}
@media only screen and (max-width: 560px) {
  .program-dropdown-box {
    width: 264px;
  }
  .nav-link > a,
  .nav-link > .shortcut-link {
    margin: 0 8px;
  }
}

/*** Account Dropdown Styles ***/
.account-dropdown {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
/* Account Dropdown Header Styles */
.account-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.account-dropdown-header .user-profile-pic {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 100%;
}
.account-dropdown-header .user-name {
  margin-left: 8px;
}
.account-dropdown-header .user-name i {
  vertical-align: middle;
  margin-top: -3px;
}
/* Account Dropdown Box Styles */
.dropdown-box {
  background: #fff;
  border-radius: 3px;
  padding: 16px;
  position: absolute;
  box-shadow:
    0 0 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  transition: opacity 300ms linear;
  opacity: 0;
  visibility: hidden;
}
.dropdown-box.open {
  opacity: 1;
  visibility: visible;
}
.account-dropdown-box {
  width: 220px;
  right: 0;
  top: 54px;
}
.account-dropdown-box-header {
  border-bottom: 1px solid #edecec;
  padding-bottom: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.account-dropdown-box-header img {
  margin-right: 16px;
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 100%;
}
.account-dropdown-box-header .user-name {
  margin: 2px 0 6px 0;
}
.account-dropdown-box-body p {
  transition: color 300ms linear;
}
.account-dropdown-box-body p:last-child:hover {
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
}
@media only screen and (min-width: 560px) and (max-width: 648px) {
  .account-dropdown .user-name.header-user-name {
    display: none;
  }
}

/***** ACCOUNT INFO HEADER STYLES *****/
.account-info-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin: 0 -24px 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #edecec;
}
.account-info-header > div {
  flex: 1;
  padding: 0 24px;
}
.account-info-header > div:nth-child(2) {
  padding: 0 40px;
  flex: 1;
  border-right: 1px solid #edecec;
  border-left: 1px solid #edecec;
}
.account-info-header > div:nth-child(2):last-child {
  border-right: none;
}
.account-info-header div .small-text {
  font-size: 11px;
}
.account-info-header div p:not(.flex) i {
  vertical-align: middle;
  float: left;
  margin-bottom: 16px;
}
.account-info-header div p i {
  font-size: 20px;
}
@media only screen and (max-width: 1040px) {
  .account-info-header {
    display: block;
    overflow: auto;
  }
  .account-info-header > div:first-child {
    padding: 0;
    margin-bottom: 24px;
  }
  .account-info-header > div:nth-child(2) {
    float: left;
    max-width: 47%;
    border-left: none;
    padding-left: 0;
    margin-right: 16px;
    padding: 0 16px 0 0;
  }
  .account-info-header > div:nth-child(3) {
    float: left;
    max-width: 47%;
    padding: 0;
  }
  .account-info-header div p i {
    margin-bottom: 0;
  }
  .account-info-header div p span {
    display: inline-block;
    max-width: 224px;
    line-height: 1.5;
    margin-top: -4px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 640px) {
  .account-info-header div p i {
    display: none;
  }
}
@media only screen and (max-width: 560px) {
  .account-info-header + span .modal-container {
    background: rgba(0, 0, 0, 0.4);
  }
  .account-info-header {
    overflow: visible;
  }
  .account-info-header > div:first-child {
    margin-bottom: 16px;
  }
  .account-info-header p.large-text {
    font-size: 16px;
  }
  .account-info-header p:nth-child(2) {
    font-size: 12px;
  }
}

/***** DIRECTORY PAGE STYLES *****/
.directory-page {
  min-height: 101vh;
}
.directory-page.overflow-hidden {
  min-height: auto;
  height: calc(100vh - 72px);
}

/*** Directory Table Row Styles ***/
.directory-table-row {
  display: grid;
  grid-template-columns: 0.75fr 1fr 1fr 0.75fr 1fr;
  grid-column-gap: 24px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  transition: box-shadow 250ms linear;
  cursor: pointer;
  padding: 12px 16px;
  margin-bottom: 12px;
}
.directory-table-row:hover {
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow:
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
}
.directory-table-row.teams-directory-table-row {
  grid-template-columns: repeat(7, 1fr);
  cursor: default;
}
.directory-table-row.teams-directory-table-row:hover {
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: none;
}
.directory-table-row.teams-directory-table-row
  .directory-contact-programs-info {
  grid-column: span 2;
}
.table-label {
  text-transform: uppercase;
  color: $accent-text;
  margin-bottom: 8px;
  font-size: 11px;
  font-family: AvenirMedium;
}
.table-data {
  line-height: 1.2;
}
.table-data.email {
  word-wrap: break-word;
  word-break: break-all;
}
.table-data.email span {
  display: inline-block;
  max-width: calc(100% - 24px);
  vertical-align: top;
}
.table-data-sub {
  color: $accent-text-dark;
  font-size: 12px;
  margin-top: 2px;
}
.directory-contact-contact-info {
  display: contents;
}
.directory-contact-contact-info i {
  vertical-align: middle;
  font-size: 15px;
  margin-right: 8px;
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .directory-table-row {
    grid-template-columns: repeat(4, auto);
  }
  .directory-table-row > div:nth-child(1) {
    grid-column: span 4;
  }
  .directory-table-row > div:nth-child(2) {
    order: 3;
  }
  .directory-table-row > div:nth-child(3) {
    order: 4;
  }
  .directory-table-row > div:nth-child(4) {
    order: 2;
  }
  .directory-table-row > div:nth-child(5) {
    order: 4;
  }
  .directory-contact-contact-info {
    display: block;
  }
  .directory-contact-contact-info > div:first-child {
    margin-bottom: 8px;
  }
  .directory-contact-name {
    font-size: 16px;
    font-family: AvenirHeavy;
    margin-bottom: 16px;
  }
  .directory-table-row.teams-directory-table-row {
    grid-template-columns: repeat(2, 1fr);
  }
  .directory-table-row.teams-directory-table-row > div:nth-child(1) {
    grid-column: span 2;
  }
  .directory-table-row.teams-directory-table-row > div:nth-child(2) {
    order: 2;
  }
  .directory-table-row.teams-directory-table-row > div:nth-child(3) {
    order: 3;
  }
  .directory-table-row.teams-directory-table-row > div:nth-child(4) {
    order: 4;
  }
  .teams-directory-table-row .directory-contact-contact-info > div {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 560px) {
  .directory-table-row,
  .directory-table-row.teams-directory-table-row {
    grid-template-columns: repeat(2, 1fr);
  }
  .directory-table-row > div:nth-child(1) {
    grid-column: span 2;
    margin-bottom: 12px;
    font-family: AvenirHeavy;
  }
  .directory-table-row.teams-directory-table-row > div:nth-child(1) {
    margin-bottom: 8px;
  }
  .directory-table-row.teams-directory-table-row > div:nth-child(2) {
    margin-bottom: 12px;
  }
  .directory-contact-contact-info {
    font-size: 12px;
  }
}
@media only screen and (max-width: 496px) {
  .directory-table-row {
    display: block;
  }
  .directory-contact-contact-info > div:first-child {
    margin-bottom: 8px;
  }
  .teams-directory-table-row .directory-contact-contact-info > div {
    margin-bottom: 8px;
  }
}
.directory-table-row.teams-directory-table-row .tag {
  font-size: 11px;
  padding: 0 8px;
  height: 18px;
}
/*** Directory Empty State Styles ***/
.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 14vh;
  padding-bottom: 14vh;
}

/***** DIRECTORY DETAILS PAGE STYLES *****/
.directory-details-page > p.link-text {
  display: inline-block;
}
.directory-card {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 32px 40px;
  background: #fff;
}
.empty-state img {
  height: 210px;
}
@media only screen and (max-width: 1040px) {
  .directory-card {
    padding: 24px;
  }
}
@media only screen and (max-width: 560px) {
  .directory-card {
    padding: 16px;
  }
  .empty-state img {
    height: 156px;
  }
  .empty-state .xxl-text {
    font-size: 16px;
  }
  .empty-state .medium-text {
    font-size: 12px;
  }
}
/*** Contact Details Card Styles ***/
.cd-card {
  margin: 40px 0;
  display: flex;
}
.cd-card-left {
  display: flex;
  justify-content: space-between;
  min-width: 504px;
}
.cd-profile-pic {
  margin-right: 24px;
}
.cd-profile-pic img {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 100%;
}
.profile-pic-small {
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 3px;
}
.cd-contact-info i {
  vertical-align: middle;
  font-size: 18px;
  margin-right: 4px;
}
.cd-spouse {
  min-height: 40px;
}
.cd-spouse label {
  font-size: 11px;
  display: block;
  color: $accent-text;
  text-transform: uppercase;
}
.cd-card-divider {
  width: 1px;
  background: #edecec;
  margin: 0 40px;
}
.cd-card-right {
  line-height: 2;
}
.cd-card-right span.fw-700 {
  width: 168px;
  display: inline-block;
}
@media only screen and (min-width: 768px) and (max-width: 1040px) {
  .cd-profile-pic img {
    height: 80px;
    width: 80px;
  }
  .cd-card-left {
    min-width: auto;
  }
  .cd-card-right > p {
    display: flex;
    align-items: center;
  }
  .cd-card-right span.fw-700 {
    width: auto;
    margin-right: 8px;
  }
}
@media only screen and (max-width: 768px) {
  .cd-card {
    display: block;
    font-size: 12px;
    margin-top: 16px;
  }
  .cd-card-left {
    display: flex;
  }
  .cd-profile-pic img {
    height: 64px;
    width: 64px;
  }
  .cd-card-left {
    min-width: auto;
  }
  .cd-card-left div:last-child {
    flex: 1;
  }
  .cd-card-left > div > div:first-child {
    display: flex;
    align-items: center;
  }
  .cd-card-left > div > div:first-child .fw-900.xxl-text {
    font-size: 16px;
  }
  .cd-card-left > div > div:first-child .small-text {
    font-size: 10px;
  }
  .cd-card-divider {
    width: 100%;
    height: 1px;
    margin: 16px 0;
  }
}
@media only screen and (max-width: 560px) {
  .cd-profile-pic {
    margin-right: 16px;
  }
  .cd-card-left {
    display: block;
  }
  .cd-contact-info.flex {
    display: block;
  }
  .cd-contact-info.flex .inline-block {
    display: block;
  }
  .cd-contact-info.flex .inline-block:first-child {
    margin-bottom: 16px;
  }
}
/*** Chabad House Card Styles ***/
.ch-card {
  margin-bottom: 16px;
}
.ch-card:last-child {
  margin-bottom: 0;
}
.ch-card-top {
  display: grid;
  grid-template-columns: 2fr 1.5fr 0.5fr;
  grid-column-gap: 16px;
}
.ch-contact-info,
.ch-personnel {
  margin-top: 24px;
}
.ch-contact-info p {
  clear: both;
}
.ch-contact-info .mb-16 {
  display: flex;
}
.ch-contact-info .fw-700 {
  display: inline-block;
  width: 120px;
  min-width: 120px;
}
.ch-contact-info .fw-700 + span {
  max-width: calc(100% - 108px);
  display: inline-block;
  vertical-align: top;
}
.ch-personnel-member {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.ch-personnel-member img {
  height: 24px;
  width: 24px;
  border-radius: 3px;
  object-fit: cover;
}
.ch-personnel-member *:nth-child(2) {
  display: inline-block;
  width: 200px;
}
.ch-schools {
  padding-top: 32px;
  border-top: 1px solid #edecec;
  margin-top: 16px;
}
.ch-school {
  display: grid;
  grid-template-columns: 1fr 104px;
  grid-gap: 16px;
  align-items: center;
  border-bottom: 1px solid #edecec;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.ch-school:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ch-school-contact-info {
  display: flex;
  justify-content: space-between;
}
.ch-school-contact-info p:first-child {
  margin-right: 16px;
}
.ch-school .contact-info i {
  font-size: 18px;
  margin-right: 4px;
  vertical-align: middle;
}
@media only screen and (min-width: 560px) and (max-width: 1040px) {
  .ch-card-top {
    grid-template-columns: repeat(2, auto);
  }
  .ch-card-top .social-links {
    justify-self: end;
  }
  .ch-contact-info,
  .ch-personnel {
    grid-column: span 2;
  }
  .ch-personnel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .ch-school-contact-info {
    display: block;
  }
  .ch-school-contact-info .contact-info:first-child {
    margin-bottom: 4px;
  }
}
@media only screen and (max-width: 560px) {
  .ch-card {
    font-size: 12px;
  }
  .ch-card-top {
    display: block;
  }
  .ch-card .uppercase-text.accent-text-dark {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .ch-card-top .xl-text {
    font-size: 16px;
  }
  .ch-card-top .social-links i {
    font-size: 16px;
    margin-left: 0;
    margin-right: 12px;
    margin-top: 8px;
  }
  .ch-contact-info span.fw-700 {
    display: inline-block;
    width: 80px;
  }
  .ch-personnel-header {
    border-top: 1px solid #edecec;
    padding-top: 16px;
    margin-top: 16px;
  }
  .ch-personnel-member a {
    width: 160px;
  }
  .ch-personnel .small-text {
    font-size: 10px;
  }
  .ch-school {
    grid-template-columns: 1fr 40px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  .ch-school > .flex {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
  .ch-school > .flex .tag {
    order: -1;
    margin-bottom: 6px;
    margin-left: 0;
  }
  .ch-school .social-links {
    display: none;
  }
  .ch-school-contact-info {
    display: flex;
    color: rgba(0, 0, 0, 0.6);
  }
  .ch-school-contact-info p:first-child {
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid rgba(0, 0, 0, 0.4);
  }
  .ch-school-contact-info i {
    display: none;
  }
  .ch-school .text-center {
    text-align: right;
    font-size: 10px;
  }
}
/*** School Card Styles ***/
.school-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr 0.75fr 0.5fr;
  align-items: center;
  margin-top: 24px;
  border-bottom: 1px solid #edecec;
  padding-bottom: 16px;
  margin-bottom: 24px;
}
.school-card-header .contact-info i {
  font-size: 20px;
  margin-right: 4px;
  vertical-align: middle;
}
.school-card-header .social-links {
  justify-self: flex-end;
}
.school-contact-info {
  display: contents;
}
.school-card-section:not(:last-of-type) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.school-card-section.two-column-section {
  display: grid;
  grid-template-columns: 280px 1fr;
  row-gap: 14px;
}
.school-card-section.four-column-section {
  display: grid;
  grid-template-columns: 184px 1fr 184px 1fr;
  row-gap: 14px;
  max-width: 720px;
}
.comma-separated:not(:last-child):after {
  content: ",";
  margin-right: 8px;
}
@media only screen and (max-width: 1040px) {
  .school-card-header {
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 24px;
  }
  .school-card-header > *:nth-child(1) {
    order: 1;
  }
  .school-card-header > *:nth-child(2) {
    order: 3;
  }
  .school-card-header > *:nth-child(3) {
    order: 2;
  }
  .school-contact-info {
    display: block;
  }
  .school-contact-info .contact-info:last-child {
    margin-left: 40px;
  }
  .school-card-section.two-column-section,
  .school-card-section.four-column-section {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 560px) {
  .school-card-header > .flex {
    flex-direction: column;
    grid-column: span 2;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  .school-card-header > .flex .tag {
    order: -1;
    margin-bottom: 6px;
    margin-left: 0;
  }
  .school-card-section.two-column-section p,
  .school-card-section.four-column-section p {
    font-size: 12px;
  }
}

/***** BANNER STYLES *****/
.banner-wrapper {
  position: fixed;
  width: 100%;
  z-index: 2100;
}
.banner {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  background-color: #124870;
}
.banner-wrapper + .header {
  top: 48px;
}
.banner-wrapper + .header + .page-wrapper {
  padding-top: 120px;
}
.banner > p {
  font-family: "AvenirMedium";
  margin-right: 24px;
  letter-spacing: 0.5px;
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.banner .btn {
  height: 30px;
  width: auto;
  line-height: 28px;
  padding: 0 12px;
  display: block;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 3px;
  color: #fff;
  font-family: "AvenirMedium";
  border: 1px solid transparent;
  transition: border 300ms linear;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner a:hover {
  border: 1px solid #fff;
}
@media only screen and (max-width: 1040px) {
  .banner-wrapper + .header + .page-wrapper {
    padding-top: 104px;
  }
}
@media only screen and (max-width: 560px) {
  .banner-wrapper {
    z-index: 210;
  }
  .banner {
    justify-content: space-between;
  }
  .banner > p {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 12px;
  }
}

/***** BANNER CARDS STYLES *****/
.banner-cards {
  background: #fff;
}
.banner-cards .container {
  overflow: auto;
  padding: 24px 40px;
  display: flex;
  justify-content: space-between;
}
.banner-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.banner-card:not(:last-child) {
  margin-right: 24px;
}
.banner-card .btn-accent:hover:not(:disabled) {
  color: #000000;
  background: #fee79a;
}
.banner-card .btn-accent {
  background-color: #fec609;
  font-family: "AvenirMedium";
  border: none;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  padding: 0 16px;
  color: #000000;
}
.banner-card-txt {
  font-family: "AvenirHeavy";
  margin-right: 24px;
  line-height: 1.5;
}
@media only screen and (max-width: 1040px) {
  .banner-card {
    flex-direction: column;
    align-items: flex-start;
  }
  .banner-card .btn-accent {
    display: inline-block;
    margin-top: 8px;
  }
}
@media only screen and (max-width: 560px) {
  .banner-cards .container {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .banner-card {
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .banner-card:not(:last-child) {
    margin: 0;
    border-bottom: 1px solid #edecec;
  }
  .banner-card-txt {
    max-width: 40%;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 12px;
  }
}

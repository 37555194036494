@font-face {
  font-family: AvenirBlack;
  src: url(/fonts/Avenir-Black.woff);
}
@font-face {
  font-family: AvenirHeavy;
  src: url(/fonts/Avenir-Heavy.woff);
}
@font-face {
  font-family: AvenirMedium;
  src: url(/fonts/Avenir-Medium.woff);
}
@font-face {
  font-family: AvenirRoman;
  src: url(/fonts/Avenir-Roman.woff);
}
@font-face {
  font-family: AvenirBook;
  src: url(/fonts/Avenir-Book.woff);
}

body {
  font-family: AvenirRoman;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
a {
  text-decoration: none;
  color: inherit;
}

.archivo-regular {
  font-family: "Archivo";
  font-weight: 300;
}
.archivo-extra-bold {
  font-family: "Archivo";
  font-weight: 800;
}
.archivo-black {
  font-family: "Archivo Black";
}
.montserrat {
  font-family: "Montserrat";
}

/***** FONT WEIGHT STYLES *****/
.fw-600 {
  font-weight: 600;
}

.fw-900 {
  font-family: AvenirBlack;
}
.fw-700 {
  font-family: AvenirHeavy;
}
.fw-500 {
  font-family: AvenirMedium;
}
.fw-400 {
  font-family: AvenirRoman;
}
.fw-300 {
  font-family: AvenirBook;
}

strong {
  font-family: AvenirBlack;
}

/***** FONT SIZE STYLES *****/
.xs-text {
  font-size: 10px;
}
.small-text {
  font-size: 12px;
}
.medium-text {
  font-size: 16px;
}
.large-text {
  font-size: 18px;
}
.xl-text {
  font-size: 20px;
}
.xxl-text {
  font-size: 24px;
}
.xxxl-text {
  font-size: 40px;
}

.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-34 {
  font-size: 34px;
}

@media only screen and (max-width: 560px) {
  .mobile-xxxl-text {
    font-size: 28px;
  }
}

/***** LINK STYLES *****/
.link-text {
  color: $accent-color;
  font-family: AvenirMedium;
  transition: opacity 300ms linear;
  cursor: pointer;
  text-decoration: none;
}
.link-text.material-icons {
  font-family: "Material Icons";
}
.link-text:hover {
  opacity: 0.5;
}
.link-text-no-hover:hover {
  opacity: 1;
}
.link-text.no-hover {
  @extend .link-text-no-hover;
}
.link-text.disabled {
  @extend .link-text-no-hover;
  cursor: not-allowed;
  color: $accent-text;
}
.link-text-heavy {
  font-family: AvenirHeavy;
}
.link-text .back-arrow {
  font-size: 18px;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 8px;
}
.link-with-icon i {
  color: $accent-text-light;
  transition: color 300ms linear;
}
.link-with-icon:hover i {
  color: $accent-color-dark;
}
.page-wrapper-mobile .link-with-icon i {
  color: $accent-color-dark;
}
.link-text-secondary {
  transition: opacity 300ms linear;
}
.link-text-secondary:hover {
  opacity: 0.6;
  cursor: pointer;
}
.link-text-secondary.no-hover:hover {
  opacity: 1;
}
.link-text-secondary.disabled {
  @extend .link-text-no-hover;
  cursor: not-allowed;
  color: $accent-text;
}
.tooltip .link-text {
  @extend .link-text;
  color: #ffc602;
}

/***** TEXT COLOR STYLES ****/
.accent-text-light {
  color: $accent-text-light;
}
.accent-text {
  color: $accent-text;
}
.secondary-text {
  color: $secondary-text;
}
.accent-text-dark {
  color: $accent-text-dark;
}
.accent-text-medium {
  color: $accent-text-medium;
}
.accent-text-secondary {
  color: $accent-color;
}
.accent-text-secondary-color {
  color: $accent-color-secondary;
}
.error-text {
  color: $error-color;
}

/***** TEXT TRANSFORM STYLES *****/
.uppercase-text {
  text-transform: uppercase;
}
.italic-text {
  font-style: italic;
}
.nowrap-text {
  white-space: nowrap;
}

/***** TEXT ALIGN STYLES *****/
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
@media only screen and (max-width: 560px) {
  .mobile-text-center {
    text-align: center;
  }
  .mobile-text-right {
    text-align: right;
  }
  .mobile-text-left.text-right {
    text-align: left;
  }
}

/***** TEXT DECORATION STYLES *****/
.underline-text {
  text-decoration: underline;
}

/***** TITLE STYLES *****/
.page-title {
  font-family: AvenirBlack;
  font-size: 24px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 560px) {
  .page-title {
    font-size: 20px;
  }
}

/***** PROFILE PAGE STYLES *****/
@media only screen and (max-width: 1040px) {
  .profile-page.page.container {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 560px) {
  .profile-page.page.container {
    padding: 0;
  }
}

/*** Profile Page Subheader Styles ***/
.profile-page-subheader {
  padding: 0;
  height: 56px;
}
.profile-page-subheader-nav > li {
  margin-right: 48px;
  height: 56px;
  display: flex;
  align-items: center;
}
.profile-page-subheader-nav > li > a,
.profile-page-subheader-nav > li > p {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 300ms linear;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.profile-page-subheader-nav > li a {
  transition: color 300ms linear;
}
.profile-page-subheader-nav > li a:hover,
.profile-page-subheader-nav > li > .active-subheader-nav-link {
  color: $accent-color;
  border-bottom: 2px solid $accent-color;
}
.profile-dropdown-box {
  font-size: 12px;
  top: 56px;
  min-width: 280px;
  line-height: 2;
}
.profile-dropdown-box.open {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}
.profile-dropdown-box li a:hover,
.profile-dropdown-box .active-subheader-nav-link {
  border: none;
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .profile-page-subheader {
    position: fixed;
    width: 100%;
    z-index: 2;
  }
}
@media only screen and (max-width: 560px) {
  .profile-page-subheader {
    background: #fafafa;
  }
  // .profile-page-subheader .container .flex:nth-child(2) {display: none;}
  .profile-page-subheader .container .flex:nth-child(2) .btn {
    height: 48px;
    width: 100%;
  }
  .profile-page-subheader-nav > li {
    margin-right: 24px;
  }
  .profile-dropdown-box {
    width: 98%;
    left: 1%;
    top: 112px;
  }
}
.profile-tooltip .tooltip {
  top: 100%;
  bottom: auto;
  background-color: $error-color;
}
.profile-tooltip .material-icons {
  color: $error-color;
}

/*** Profile Nav Card Styles ***/
.profile-nav-card {
  padding: 24px;
  width: 216px;
  position: fixed;
  top: 112px;
}
.banner-wrapper + header + .page-wrapper .profile-nav-card {
  top: 160px;
}
.profile-sections-nav > li:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.profile-link {
  text-transform: uppercase;
  font-family: AvenirHeavy;
  letter-spacing: 0.75px;
  transition: color 300ms linear;
}
.profile-link:hover {
  color: $accent-color;
}
.active-profile-link {
  color: $accent-color;
  margin-left: -25px;
  padding-left: 23px;
  border-left: 2px solid $accent-color;
}
.profile-nav-section-header {
  text-transform: uppercase;
  font-family: AvenirHeavy;
  letter-spacing: 0.75px;
}
.profile-add-icon {
  font-size: 20px;
  margin-top: -2px;
  font-family: "Material Icons";
}
/* Profile Sections Nested Nav Styles */
.profile-sections-nested-nav {
  margin-top: 12px;
  font-size: 12px;
}
.profile-sections-nested-nav.school {
  height: calc(100vh - 350px);
  overflow-y: auto;
}
.profile-sections-nested-nav > li {
  transition: color 300ms linear;
}
.profile-sections-nested-nav > li:hover {
  color: $accent-color;
}
.profile-sections-nested-nav > li:not(:last-child) {
  margin-bottom: 12px;
}

/*** Profile Form Card Styles ***/
.profile-form-card {
  padding: 24px;
  margin-left: 240px;
  width: calc(100% - 368px);
  margin-bottom: 24px;
}
@media only screen and (max-width: 1040px) {
  .profile-form-card {
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
}
@media only screen and (max-width: 560px) {
  .profile-form-card {
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
    height: calc(100vh - 272px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}
/* Profile Form Card Input Styles */
.profile-form-inputs {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 16px;
}
.profile-form-inputs.profile-outreach-form-inputs,
.profile-form-inputs.profile-activities-form-inputs {
  display: block;
}
.profile-form-inputs.profile-activities-form-inputs .custom-input,
.profile-form-inputs.profile-activities-form-inputs .radio-container {
  max-width: 200px;
}
.profile-form-inputs.profile-activities-form-inputs
  .radio-button-container
  input:checked
  + label,
.profile-form-inputs.profile-activities-form-inputs
  .radio-button-container
  input:not(:checked)
  + label,
.profile-form-inputs.profile-activities-form-inputs
  .custom-checkbox[type="checkbox"]
  + label {
  top: 0;
  height: 18px;
}
.profile-form-inputs .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
.profile-form-section .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
.profile-form-card label.small-text.accent-text {
  min-width: 104px;
  display: inline-flex;
  padding-right: 8px;
}
label.error {
  color: $error-color;
}
.profile-form-inputs .radio-container input[type="radio"] + label {
  width: 50%;
  min-width: auto;
  margin-bottom: 0;
  display: flex;
}
.profile-form-card .radio-button-container label {
  min-width: auto;
  width: auto;
}
.profile-form-inputs .radio-container,
.profile-form-inputs .custom-input {
  width: 100%;
}
.profile-form-inputs .error-message:not(.phone-input-error) {
  bottom: -13px;
  left: 104px;
  font-size: 11px;
}
@media only screen and (max-width: 560px) {
  .profile-form-inputs {
    display: block;
  }
  .profile-form-inputs > .flex {
    display: block;
    margin-right: 0;
  }
  .profile-form-inputs > .flex:not(:last-child) {
    margin-bottom: 16px;
  }
  .profile-form-inputs .error-message {
    left: 0;
  }
  .profile-form-card label {
    margin-bottom: 8px;
    width: auto;
  }
  .profile-form-card label.small-text.accent-text {
    min-width: unset;
    width: auto;
  }
  .profile-form-card .radio-container label {
    margin-bottom: 4px;
    height: 100%;
  }
  .profile-form-inputs .radio-container input[type="radio"] + label {
    font-size: 12px;
  }
  .profile-add-icon.hidden {
    display: none;
  }
}
/* Account Info Styles */
.account-info .custom-input:disabled {
  opacity: 1;
}
.account-info.profile-form-inputs > .flex {
  display: flex;
}
/* Personal Info Styles */
.profile-pic-container img,
.profile-pic-container svg#loading {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 44px;
}
.address-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
@media only screen and (max-width: 560px) {
  .flex.profile-pic-container {
    display: flex;
  }
  .profile-pic-container img {
    margin-right: 32px;
  }
  .address-grid {
    grid-template-columns: 1fr 1fr;
  }
}
/* Spouse Info Styles */
.spouse-card {
  border: 1px solid #edecec;
  padding: 0 16px 24px;
}
.spouse-disabled label {
  opacity: 0.5;
}
@media only screen and (max-width: 560px) {
  .spouse-card {
    display: block;
    padding: 0;
    border: none;
  }
  .spouse-card .profile-form-inputs > .flex {
    display: block;
    margin-bottom: 16px;
    margin-right: 0;
  }
}
/* Contact Info Styles */
.profile-form-contact-info {
  display: grid;
  grid-template-columns: 96px 186px 192px 1fr 12px;
  grid-gap: 16px 12px;
}
_:-ms-lang(x),
.profile-form-contact-info {
  display: block;
}
.profile-form-contact-info > p {
  grid-column-start: span 5;
}
.profile-form-contact-info > p span {
  line-height: 1.4;
}
.profile-form-contact-info > div {
  display: contents;
}
_:-ms-lang(x),
.profile-form-contact-info > div {
  display: flex;
}
.profile-form-contact-info .custom-select {
  min-width: 96px;
}
.profile-form-contact-info .custom-input {
  width: 100%;
}
.profile-form-contact-info > div > .custom-input {
  min-width: 200px;
}
.profile-form-contact-info .radio-button-container label {
  padding-left: 16px !important;
  margin-right: 8px !important;
}
.contact-error-message {
  color: $error-color;
}
@media only screen and (max-width: 560px) {
  .radio-button-container [type="checkbox"]:checked + label,
  .radio-button-container [type="checkbox"]:not(:checked) + label {
    top: -10px;
  }
  .profile-form-contact-info {
    display: block;
  }
  .profile-form-contact-info .mobile-card {
    display: grid;
    grid-template-columns: 60% 40%;
    border: 1px solid #edecec;
    border-radius: 3px;
    width: 100%;
    padding: 16px 16px 8px;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(1) {
    order: 1;
    grid-column-start: span 2;
    margin-bottom: 8px;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(2) {
    order: 2;
    grid-column-start: span 2;
    margin-bottom: 16px;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(3) {
    order: 3;
    margin-bottom: 0;
    margin-left: -16px;
    padding-left: 16px;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(4) {
    order: 4;
    margin-bottom: 0;
    border-top: 1px solid #e0e0e0;
    margin-right: -16px;
    padding-right: 16px;
    height: 100%;
    padding-top: 8px;
  }
  .profile-form-contact-info .hover-delete {
    width: 100% !important;
    text-align: right;
  }
  .profile-form-contact-info
    .mobile-card
    .radio-button-container
    [type="checkbox"]:checked
    + label,
  .profile-form-contact-info
    .mobile-card
    .radio-button-container
    [type="checkbox"]:not(:checked)
    + label {
    margin-right: -2px;
  }
  .profile-form-contact-info .custom-select {
    border: 1px solid #edecec;
    border-radius: 3px;
  }
  .profile-form-contact-info .radio-button-container label + span {
    display: block;
    padding-left: 8px;
  }
  .profile-form-contact-info .delete-container {
    grid-column-start: span 2;
    margin-bottom: 16px;
  }
}
/* Family Info Styles */
.child-row {
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 9px));
  border-radius: 3px;
  border: 1px solid #edecec;
  padding: 8px 8px 0 8px;
  grid-column-gap: 16px;
  width: calc(100% - 32px);
  margin-bottom: 16px;
}
.child-row > div {
  position: relative;
}
.child-row label {
  font-family: AvenirMedium;
  text-transform: uppercase;
  font-size: 10px;
}
.child-row .custom-input {
  height: 24px;
  margin-bottom: 8px;
  border-radius: 0;
  max-width: 100%;
  border: none;
  border-bottom: 1px solid #edecec;
  width: 100%;
}
.child-row .custom-input.error {
  border: none;
  border-bottom: 1px solid $error-color;
}
.child-row .custom-select > div:first-of-type {
  border: none;
  margin-left: -8px;
  height: 30px;
}
.child-row input {
  font-size: 13px;
  padding: 0;
}
.child-row input[type="date"] {
  font-size: 11.5px;
}
.child-row select {
  padding: 0;
  background: transparent;
  font-size: 13px;
  width: 100%;
}
.child-row input:focus,
.child-row select:focus {
  outline: none;
}
.child-row .error-message {
  bottom: 0px;
}
@media only screen and (max-width: 560px) {
  .child-row-container {
    border: 1px solid #edecec;
    border-radius: 3px;
    align-items: flex-start;
    position: relative;
    padding: 16px;
    margin-bottom: 16px;
  }
  .child-row {
    grid-template-columns: 1fr;
    border: none;
    padding: 0;
    width: 100%;
  }
  .child-row label {
    display: block;
  }
  .child-row > div:not(:last-child) {
    margin-bottom: 16px;
    width: 100%;
  }
  .child-row select:last-child {
    margin-bottom: 0;
  }
  .page-wrapper-mobile .child-row input {
    font-size: 16px;
    width: calc(100% - 8px);
  }
  .page-wrapper-mobile .child-row select {
    margin-left: 0;
    font-size: 16px;
  }
  .child-row + .material-icons {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
/* Demographics Served Info Styles */
.demographics-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 8px;
  align-items: center;
}
.demographics-grid > div {
  display: contents;
}
/* Primary Chabad House Info Styles */
.profile-form-primary-ch-info > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
_:-ms-lang(x),
.profile-form-primary-ch-info {
  display: block;
}
.profile-form-primary-ch-info > p {
  grid-column-start: span 2;
}
.profile-form-primary-ch-info > p span {
  line-height: 1.4;
}
_:-ms-lang(x),
.profile-form-primary-ch-info > div {
  display: flex;
}
.primary-ch-error-message {
  color: $error-color;
}
@media only screen and (max-width: 560px) {
  .profile-form-primary-ch-info {
    display: block;
  }
}
/* General Info Styles */
.square-image-upload {
  margin-top: 16px;
  margin-bottom: 16px;
}
.square-image-upload .profile-pic-container img {
  border-radius: 3px;
}
.program-levels-multiselect .multiselect__indicator {
  padding: 0 8px;
}
/* Associated School Info Styles */
.profile-form-associated-schools-info {
  display: grid;
  grid-template-columns: auto 56px 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
}
.profile-form-associated-schools-info > div {
  display: contents;
}
.profile-form-associated-schools-info > div label.accent-text.small-text {
  display: none;
}
.profile-form-associated-schools-info
  .radio-button-container
  [type="checkbox"]:checked
  + label,
.profile-form-associated-schools-info
  .radio-button-container
  [type="checkbox"]:not(:checked)
  + label {
  top: -12px;
}
_:-ms-lang(x),
.profile-form-associated-schools-info {
  display: block;
}
_:-ms-lang(x),
.profile-form-associated-schools-info > div {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
_:-ms-lang(x),
.profile-form-associated-schools-info > div > *:nth-child(1),
_:-ms-lang(x),
.profile-form-associated-schools-info > div > *:nth-child(3) {
  width: 40%;
}
_:-ms-lang(x),
.profile-form-associated-schools-info > div > *:nth-child(2) {
  width: 20%;
  min-width: 80px;
}
.profile-form-associated-schools-info .mobile-card > *:nth-child(2) {
  width: 24px;
  margin: 0 auto;
}
@media only screen and (max-width: 560px) {
  .profile-form-associated-schools-info {
    display: block;
  }
  .profile-form-associated-schools-info > div:nth-child(1) {
    display: none;
  }
  .profile-form-associated-schools-info > div label {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-family: AvenirMedium;
  }
  .profile-form-associated-schools-info > div > * {
    margin-bottom: 16px;
  }
  .profile-form-associated-schools-info .mobile-card {
    display: grid;
    grid-template-columns: auto auto;
    border: 1px solid #edecec;
    border-radius: 3px;
    width: 100%;
    padding: 16px 16px 8px;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(1) {
    order: 1;
    grid-column-start: span 2;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(2) {
    order: 3;
    margin-bottom: 0;
    margin-left: -16px;
    padding-left: 16px;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    padding-bottom: 4px;
    border-bottom: 1px solid #edecec;
    width: calc(100% + 32px);
    grid-column-start: span 2;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(3) {
    order: 2;
    grid-column-start: span 2;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(4) {
    order: 4;
    margin-bottom: 0;
    padding-top: 12px;
    margin-right: -16px;
    grid-column-start: span 2;
    display: flex;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(4) > p {
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
  .mobile-card .radio-button-container [type="checkbox"]:checked + label,
  .mobile-card .radio-button-container [type="checkbox"]:not(:checked) + label {
    top: 0;
    padding-left: 18px;
    line-height: 14px;
  }
  .mobile-card .radio-button-container label span {
    text-transform: none;
    font-size: 10px;
    margin-left: 8px;
  }
  .mobile-card .radio-button-container [type="checkbox"]:checked + label:before,
  .mobile-card
    .radio-button-container
    [type="checkbox"]:not(:checked)
    + label:before {
    border-radius: 3px;
    border-color: $accent-color;
    background: transparent;
    height: 14px;
    width: 14px;
    transition: background 300ms linear;
  }
  .mobile-card
    .radio-button-container
    [type="checkbox"]:checked
    + label:before {
    background: $accent-color;
  }
  .mobile-card .radio-button-container [type="checkbox"]:checked + label:after {
    background: none;
    border-radius: 0;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-48deg);
    height: 4px;
    margin-left: -1px;
    width: 10px;
  }
  .profile-form-associated-schools-info .text-center {
    text-align: left;
  }
  .profile-form-associated-schools-info .hover-delete {
    text-align: right;
  }
}
/* Contact Info - CH Form Styles */
.profile-form-contact-info.ch-form-contact-info {
  grid-template-columns: 1fr 176px 216px 1fr;
}
.profile-form-contact-info.ch-form-contact-info > p {
  grid-column-start: span 4;
}
.profile-form-contact-info.ch-form-contact-info .relative.flex label {
  width: 32px;
  min-width: auto;
}
_:-ms-lang(x),
.profile-form-contact-info > div > * {
  width: 33%;
}
_:-ms-lang(x),
.profile-form-contact-info > div > *:nth-child(3) {
  margin-left: 16px;
}
@media only screen and (max-width: 560px) {
  .profile-form-contact-info.ch-form-contact-info .mobile-card {
    display: block;
  }
  .profile-form-contact-info.ch-form-contact-info
    .mobile-card
    > *:nth-child(3) {
    margin-right: -16px;
    padding-right: 16px;
    text-align: right;
    padding-top: 8px;
    padding-bottom: 0;
  }
}
/* Stripe styles */
.profile-form-stripe-info {
  grid-template-columns: 75%;
}
.profile-form-stripe-info-field {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 4fr;
  margin-bottom: 8px;
}
.profile-form-stripe-info-input {
  max-width: unset !important;
}
.profile-form-stripe-info-label {
  font-size: 13px;
}
/* Social Media Styles */
.profile-form-social-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}
.profile-form-social-info .custom-input {
  width: calc(100% - 104px);
}
@media only screen and (max-width: 560px) {
  .profile-form-social-info {
    grid-template-columns: 1fr;
    margin-top: 16px;
  }
  .profile-form-social-info label.small-text.accent-text {
    width: 80px;
    min-width: 80px;
  }
}
/* Shluchim Styles */
.profile-form-shluchim {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.profile-form-shluchim a {
  width: 112px;
}
.profile-form-shluchim .custom-select {
  min-width: 180px;
}
/* Personnel Styles */
.profile-form-personnel-info {
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
}
.profile-form-personnel {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.profile-form-personnel img {
  height: 24px;
  width: 24px;
  border-radius: 3px;
  margin-right: 8px;
}
.personnel-new > div > div {
  width: 50%;
}
.personnel-new > div > div input {
  width: calc(100% - 104px);
}
.personnel-row-container {
  @extend .child-row-container;
}
.personnel-row {
  border: 1px solid #edecec;
  border-radius: 3px;
  margin-bottom: 15px;
  width: 100%;
  .personnel-student-input {
    width: 100%;
  }
}
.personnel-line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 18px;
  gap: 18px;
  .personnel-date-input {
    display: inline-flex;
    flex-direction: column;
  }
}
.personnel-input {
  width: 100%;
}
.personnel-manage-access-btn-container {
  margin-left: auto;
  .MuiButtonBase-root.Mui-disabled {
    .MuiButton-label {
      color: $accent-text;
    }
  }
  .MuiButtonBase-root:hover {
    background-color: white;
    .MuiButton-label {
      opacity: 0.5;
    }
  }
  .MuiButton-label {
    color: $accent-color;
  }
  .MuiButton-text {
    padding-right: 0px;
  }
  .button:hover {
    background: none;
  }
}

input.personnel-student-disabled:read-only {
  border: 1px solid #edecec;
  padding: 0 8px;
  width: 100%;
}

.personnel-manage-access-button {
  display: inline-flex;
  align-items: flex-end;
  .MuiTouchRipple-root {
    display: none;
  }
}
.personnel-date {
  height: 30px;
  border: 1px solid #edecec;
  border-radius: 3px;
  width: 100%;
}
.personnel-even-width {
  flex: 1 1 0;
}
.personnel-new-toggle {
  max-width: 350px;
  padding: 18px;
  padding-bottom: 0px;
}
.personnel-max-width {
  max-width: 194px;
}

@media only screen and (max-width: 560px) {
  .personnel-row-container {
    flex-direction: column-reverse;
    align-items: end;
  }
  .personnel-line {
    display: flex;
    flex-direction: column;
    margin: 8px 0px;

    .personnel-grow {
      flex-grow: 1;
    }
    .personnel-date-input {
      div {
        flex-grow: 1;
      }
      input {
        width: 100%;
      }
    }
    .personnel-date {
      flex-grow: 1;
    }
    .personnel-space-top {
      padding-top: 8px;
    }
  }
  .personnel-row {
    margin-bottom: 0px;
    border: 0px;
    label {
      align-items: center;
    }
    input {
      flex-grow: 1;
    }
  }

  .access-select-buttons {
    margin-top: 30px;
    justify-content: end;
  }
}

/* Intern Manage Access Modal Styles */
.access-card {
  width: 700px;
  padding: 24px;
  min-height: 400px;
  .MuiButtonBase-root:hover {
    background-color: white;
    .MuiButton-label {
      opacity: 0.5;
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiBox-root {
    margin: 0px;
    margin-bottom: 8px;
  }
  .MuiTableCell-root {
    padding: 8px;
  }
}
.access-programs-wrapper {
  margin-top: 8px;
  margin-bottom: 40px;
}
.access-select-buttons {
  color: #2774ae !important;
  text-transform: none !important;
  font-size: 12px !important;
  padding-left: 8px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin: 8px 0px !important;
}
.access-section-status {
  border-radius: 12px;
  border-radius: 12px;
  max-width: fit-content;
  padding: 4px 8px 0px;
  text-align: center;
  margin-left: auto;
  font-size: 13px;
  background: $accent-color;
  font-family: "AvenirBook";
  color: white;
}

/* Other Info Styles */
.square-footage-input-container:after {
  content: "sf";
  color: rgba(0, 0, 0, 0.38);
  position: absolute;
  right: 12px;
  top: 10px;
}
/* Studies Info Styles */
.study-area {
  min-width: 320px;
  margin-right: 24px;
}
.profile-form-inputs.profile-form-studies-info {
  display: block;
}
.profile-form-studies-info .clearable-select .custom-input {
  min-width: 320px;
  margin-right: 24px;
}
.profile-form-studies-info-grid {
  display: grid;
  grid-template-columns: 50% 20% 20% 10%;
  grid-gap: 16px;
}
.profile-form-studies-info .custom-input {
  max-width: 100%;
}
@media only screen and (min-width: 561px) {
  .profile-form-studies-info label {
    display: none !important;
  }
}
@media only screen and (max-width: 560px) {
  .profile-form-studies-info .mobile-card {
    border: 1px solid #edecec;
    border-radius: 3px;
    width: 100%;
    padding: 16px 16px 8px;
    position: relative;
  }
  .profile-form-studies-info-grid {
    grid-template-columns: 1fr;
  }
  .profile-form-studies-info-grid > i {
    position: absolute;
    right: 24px;
  }
  .profile-form-studies-info label {
    text-transform: uppercase;
    display: block;
    font-size: 10px;
    font-family: AvenirMedium;
    margin-bottom: 8px;
  }
  // .mobile-card .custom-select > div {border: none;}
  .mobile-card .custom-select__value-container {
    padding-left: 0;
  }
  .mobile-card .custom-select__indicator {
    padding-right: 0;
  }
  .mobile-card .custom-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0;
  }
}
/* Population Info Styles */
@media only screen and (max-width: 560px) {
  .profile-form-inputs .flex + p.fw-900.accent-text {
    margin-bottom: 16px;
    margin-top: 24px;
  }
}
/* Attributes Info Styles */
.attributes-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 32px;
  grid-gap: 12px;
}
.attributes-grid label {
  width: auto;
  min-width: auto;
  font-size: 12px;
  padding-left: 8px;
}
.attributes-grid label span {
  margin-top: 2px;
  line-height: 1.3;
}
.attributes-grid-input > div {
  display: flex;
}
.attributes-grid-input.flex .custom-input {
  width: 80px;
}
@media only screen and (max-width: 1040px) {
  .attributes-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 560px) {
  .school-attributes-info > *:not(:first-child) {
    display: none;
  }
  .school-attributes-info-title:not(:first-child) i {
    display: none !important;
  }
  .attributes-grid {
    grid-template-columns: 1fr;
    display: block;
  }
  .attributes-grid-input {
    display: flex;
    align-items: center;
  }
  .attributes-grid-input label {
    padding-left: 4px;
  }
}
/* Profile Form Hover Delete Styles */
@media only screen and (min-width: 1041px) {
  .hover-delete {
    opacity: 0;
    visibility: hidden;
    transition: all 300ms linear;
    height: 32px;
    display: flex;
    align-items: center;
  }
  .hover-delete-container:hover .hover-delete {
    opacity: 1;
    visibility: visible;
  }
}
/* Profile Form Mobile Styles */
.profile-form-section > p.accent-text-dark i {
  display: none;
}
.profile-form-section > p.accent-text-dark i.spouse-info-icon {
  display: block;
}
.profile-form-section i.collapsible {
  display: none;
}
@media only screen and (max-width: 560px) {
  /* Profile Form Card Mobile Divider Styles */
  .profile-form-card hr.mobile-divider {
    height: 1px;
    width: calc(100% + 32px);
    margin: 24px -16px;
    border: 0.5px solid #edecec;
  }
  .profile-form-card hr.mobile-divider + .mt-48 {
    margin-top: 24px;
  }
  .profile-form-card hr.mobile-divider + .mt-32 {
    margin-top: 24px;
  }
  .profile-form-card hr.mobile-divider + .mb-16 {
    margin-bottom: 0;
  }
  .profile-form-card hr.mobile-divider + .mb-8 {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
  .profile-form-tabs-container {
    margin-left: 16px;
  }
  /* Profile Form Card Mobile Collapsing Sections */
  .mobile-tab-collapsed .profile-form-inputs,
  .mobile-tab-collapsed .profile-form-contact-info,
  .mobile-tab-collapsed .profile-form-family-info,
  .mobile-tab-collapsed .profile-form-primary-ch-info {
    height: 0;
    overflow: hidden;
    margin-top: 0;
  }
  .mobile-tab-collapsed .profile-form-inputs.spouse-card {
    padding: 0;
    border: none;
  }
  /* Profile Form Card Mobile Sections */
  .profile-form-sections {
    min-height: calc(100vh - 273px);
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
  .profile-form-sections.full-height {
    justify-content: space-around;
  }
  .profile-form-section {
    border-bottom: 1px solid #edecec;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
  }
  .profile-form-section > p.accent-text-dark i {
    display: block;
  }
  .profile-form-section > p.accent-text-dark i.spouse-info-icon.hidden {
    display: none;
  }
  .profile-form-section i.collapsible {
    display: block;
  }
  // .profile-form-section.mobile-tab-collapsed {height: calc(25% - 19px);}
  .profile-form-section.mobile-tab-collapsed:first-child {
    height: 64px;
  }
  .profile-form-sections.full-height > .profile-form-section:last-child {
    border-bottom: none;
  }
  .profile-form-section .mt-48,
  .profile-form-section > .mt-32 {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  .profile-form-section > div > p {
    cursor: pointer;
  }
}
/* Profile Save Button */
.profile-save-btn-container {
  position: fixed;
  top: 112px;
}
.profile-save-btn {
  width: 104px;
  height: 40px;
  line-height: 40px;
}
.profile-save-btn-font-size {
  font-size: 11px;
}
.profile-save-btn-container p {
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-top: 12px;
}
.profile-save-btn-container p i {
  font-size: 13px;
  margin-right: 4px;
}
.profile-save-btn-container .error-message {
  position: relative;
  width: 140px;
  display: block;
  line-height: 1.2;
}
.no-display {
  display: none;
}

@media only screen and (min-width: 1040px) {
  .banner-wrapper + header + .page-wrapper .profile-save-btn-container {
    top: 160px;
  }
}
@media only screen and (max-width: 1040px) {
  .profile-save-btn {
    height: 32px;
    line-height: 32px;
    position: unset;
  }
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .profile-save-btn-container {
    position: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 24px;
  }
  .profile-save-btn-container .error-message {
    position: unset;
    width: auto;
  }
}
@media only screen and (max-width: 560px) {
  .profile-save-btn-container {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    z-index: 10;
    height: 116px;
    background: #fff;
    width: calc(100% + 16px);
    margin-left: -16px;
    border-top: 1px solid #edecec;
    overflow: auto;
  }
  .profile-save-btn {
    width: calc(100% - 48px);
    margin-left: 32px;
    height: 48px;
    margin-top: 24px;
  }
  .profile-save-btn-font-size {
    font-size: 14px;
  }
  .profile-save-btn-container p {
    justify-content: center;
    width: calc(100% - 48px) !important;
    margin: 4px auto;
    text-align: center;
  }
  .profile-save-btn-container p br {
    display: none;
  }
  .profile-save-btn-container .error-message {
    position: relative;
    text-align: center;
    width: 100%;
    line-height: 1;
  }
}
.student-overview-header {
  margin-top: 10px;
  margin-bottom: 20px;
}
.student-overview-grid {
  margin-right: 5%;
  margin-left: 75px;
}
.student-overview-grid-item {
  width: 330px;
}
.student-overview-title {
  padding-bottom: 10px;
}
.student-overview-field {
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.student-overview-field.flex-align-top {
  align-items: unset;
}
.student-overview-icons {
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-left: 18px;
  font-size: 16px;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 5px;
}
.non-editable-icon {
  cursor: default;
}
.editable-icons:hover {
  background-color: #edecec;
  padding: 5px;
}
.inline-edit-icons {
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-left: 4px;
  font-size: 24px;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 5px;
}
.inline-edit-input {
  border: 1px solid #2774ae;
  border-radius: 3px;
  width: 230px;
}
.inline-edit-input > div:hover {
  border: none;
}
.inline-edit-inputstudent-overview-field > div {
  border: none;
}
.inline-edit-input.phone-input {
  padding-left: 10px;
}
.inline-edit-container {
  padding: 6px;
  cursor: pointer;
}
.inline-edit-container:hover {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 5px;
}
.phone-input-edit-field {
  margin-left: 8px;
}
.observance-type-card {
  background-color: #f4f4f5;
  padding: 2%;
  padding-left: 4%;
}

@media only screen and (max-width: 1040px) {
  .student-overview-icons {
    margin-bottom: 8px;
  }
  .student-overview-header {
    border-bottom: 1px solid gray;
  }
}
@media only screen and (max-width: 560px) {
  .student-overview-grid {
    margin-left: -5%;
  }
  .student-overview-icons {
    margin-bottom: 8px;
  }
  .student-overview-header {
    border-bottom: 1px solid gray;
  }
  .inline-edit-input {
    margin-top: -2px;
    height: inherit !important;
  }
}

.student-activity-list-tab {
  max-width: 100%;
  width: 280px;
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
}
.student-activity-list-tab.active {
  color: $accent-color;
  border-bottom: 2px solid $accent-color;
}

/*** Student Interactions Summary Table Styles ***/
$standard-bg-color: white;
$selected-bg-color: #f5f5f5;

@mixin fixed-position {
  position: sticky;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@mixin interactions-summary-table-part($bg-color: $standard-bg-color) {
  grid-gap: 0px 16px;
  margin-bottom: 0px;
  background-color: $bg-color;

  p,
  div {
    // need to specify the color so the elements aren't transparent
    // can't specify the actual color because that causes issues when transitioning colors - apparently different elements repaint at different speeds
    background-color: inherit;
    overflow-wrap: break-word;
  }

  // applies to first 2 p children
  & > p:nth-child(-n + 2) {
    @include fixed-position();
  }

  & > p:nth-child(1) {
    left: 0;
  }

  & > p:nth-child(2) {
    left: 270px;
    border-right: 1px solid #e0e0e0;
  }

  // selected row has a div in the first position
  & > div:nth-child(1) {
    @include fixed-position();
    left: 0;
  }

  @media only screen and (max-width: 560px) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    & > p:nth-child(2) {
      border-right: unset;
    }
  }
}

.interactions-summary-table {
  overflow-x: scroll;
  overflow-y: scroll;
  height: 600px;
}

.interactions-summary-table-filters-container .view-select {
  z-index: 10;
}
.interactions-summary-table-filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
  z-index: 10; // to display over the sticky columns of the table

  @media only screen and (max-width: 560px) {
    display: block;
  }
}

.interactions-summary-table-header {
  @extend .paginated-table-header-row;
  @include interactions-summary-table-part();

  font-family: AvenirHeavy;
  padding: 0px 16px;
  border-top: none;
  position: sticky;
  top: 0;
  z-index: 2;
}

.interactions-summary-table-row {
  @extend .paginated-table-row;
  @include interactions-summary-table-part();

  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}
.interactions-summary-table-row.selected {
  @include interactions-summary-table-part($selected-bg-color);
}

.interactions-summary-choose-column-modal {
  max-height: 96vh;
}

.interactions-summary-choose-column-modal .interaction-types-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/*** Student Goals and Metrics Screens Styles ***/
.student-engagement-page {
  @extend .students-page;
  min-height: calc(100vh - 72px);
  max-width: unset;
}

.goals-sidebar-expanded {
  width: 280px;
  min-width: 280px;
}

.goals-sidebar-collapsed {
  width: 25px;
  writing-mode: vertical-lr;
}

.engagement-goals-desktop-tab {
  @extend .tab;
  height: 25px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.engagement-metrics-chart-tooltip {
  background-color: #ffffff;
  color: #666666;
  font-size: 11px;
  border-radius: 4px;
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.5);
  padding: 4px 8px;
  white-space: pre-wrap;
  line-height: 1.5;
}

/*** Vibrancy Meter Styles ***/

.colored-box {
  font-size: xx-large;
  line-height: 0.5;
  margin-left: -5px;
  margin-right: 4px;
}

.vibrancy-meter-container {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 16px;
}

.vibrancy-meter-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0px;
}

.vibrancy-meter-card {
  padding: 16px;
}

.vibrancy-meter-gauge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.vibrancy-meter-gauge {
  width: 275px;
}

.vibrancy-meter-gauge-text {
  position: absolute;
  bottom: 24px;
  text-align: center;
}
.vibrancy-meter-gauge-score-text {
  font-size: 34px;
  font-family: "Archivo";
  font-weight: 800;
  margin-bottom: 8px;
}
.vibrancy-meter-gauge-score-label-text {
  font-size: 14px;
  font-family: "AvenirHeavy";
  margin-bottom: 8px;
  color: $secondary-text;
}
.vibrancy-meter-gauge-month-text {
  font-size: 12px;
  color: $secondary-text;
}

.metrics-calculations-link {
  display: flex;
  align-items: center;
  gap: 16px;
}

.kpi-list {
  list-style: disc outside;
}

.kpi-list li {
  margin-left: 40px;
}

.engagement-period-select {
  width: 200px;
  margin-bottom: 24px;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
}

.kpi-scorecard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.kpi-scorecard {
  width: 200px;
  border: 1px solid #e0e0e0;
}

@mixin kpi-scorecard-part() {
  display: grid;
  grid-template-columns: 131px 34px;
  gap: 18px;
  align-items: center;
  font-size: 12px;
  padding: 0px 8px;
}

.kpi-scorecard-header {
  @include kpi-scorecard-part();
  height: 48px;
}

.kpi-score-range {
  @include kpi-scorecard-part();
  height: 28px;
  border-bottom: 1px solid #e0e0e0;
}

.kpi-score-range.active {
  background-color: rgb(255, 198, 2, 0.08);
}

.engagement-period-details-header {
  border-bottom: 1px solid #edecec;
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 1fr;
}

.engagement-period-details-row {
  border-bottom: 1px solid #edecec;
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 1fr;
}

.engagement-period-details-progress-bar {
  width: 75%;
}
.engagement-period-details-progress-total {
  width: 100%;
  height: 18px;
  background-color: #edecec;
  border-radius: 2px;
}
.engagement-period-details-progress-reached {
  height: 18px;
  background-color: #77d9d2;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

@media only screen and (max-width: 560px) {
  .engagement-period-details-progress-bar {
    width: 100%;
  }
}

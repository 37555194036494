[mobilemenuopen] {
  overflow: hidden;
  height: 100vh;
}

/*** Menu Icon Styles ***/
.menu-icon-wrapper {
  position: absolute;
  top: 8px;
  z-index: 100;
  width: 20px;
  height: 40px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.menu-icon {
  position: relative;
  top: 20px;
  width: 20px;
  height: 2px;
  background: $accent-color;
  display: block;
  transform-origin: center;
  transition: 0.5s ease-in-out;
}
.menu-icon:after,
.menu-icon:before {
  transition: 0.5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: $accent-color;
}
.menu-icon:before {
  top: -6px;
}
.menu-icon:after {
  bottom: -6px;
}

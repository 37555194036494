/***** PROGRAMS PAGE STYLES *****/
/*** Program Header Styles ***/
.programs-page-header {
  border-bottom: 1px solid #edecec;
  padding: 40px 0;
}
.program-select {
  border: none;
  font-size: 14px;
  width: calc(100% - 128px);
}
.program-select > .program-select__control {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
}
.program-select__single-value {
  color: $accent-color !important;
}
.program-select__indicator-separator {
  display: none;
}
.program-select__dropdown-indicator {
  color: $accent-color !important;
}
.program-select__multi-value {
  margin-right: 16px !important;
}
.program-select__multi-value__label {
  color: #fff !important;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 3px 0 0 3px !important;
  padding-left: 24px !important;
  text-transform: uppercase;
}
.program-select__multi-value__remove {
  color: rgba(0, 0, 0, 0.3);
  border-radius: 0 3px 3px 0 !important;
  padding-right: 8px !important;
}
.program-select__multi-value__remove:hover {
  color: rgba(0, 0, 0, 0.3) !important;
}
.program-select__multi-value__remove svg {
  height: 18px;
  width: 18px;
  transition: all 300ms linear;
}
.program-select__multi-value__remove:hover svg {
  opacity: 0.5;
}
.program-select__menu {
  max-width: 300px;
}
@media only screen and (max-width: 1040px) {
  .programs-page-header {
    padding: 24px 0;
  }
}
@media only screen and (max-width: 720px) {
  .programs-page-header div.flex-justify-space {
    display: block;
  }
  .programs-page-header .search-input {
    margin-bottom: 16px;
    width: 100%;
  }
}
@media only screen and (max-width: 560px) {
  .programs-page-header {
    padding: 24px 0;
  }
}
/*** Program Card Styles ***/
.program-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(262px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 40px;
}
.program-cards-grid > div {
  display: contents;
}
.program-section-title {
  grid-column: 1 / -1;
}
.program-section-subtitle {
  grid-column: 1 / -1;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}
.program-card {
  padding: 24px 16px 24px 24px;
  height: 304px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 300ms linear;
}
.program-card.hover {
  box-shadow:
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
}
.program-new {
  min-height: 32px;
  min-width: 32px;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  border: 1.5px solid $accent-color;
  color: $accent-color;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
  font-family: "AvenirHeavy";
  margin-left: 16px;
}
.program-card label {
  color: $accent-text;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
}
.program-card .program-contact-info p {
  line-height: 1;
  font-size: 12px;
}
.program-card .program-contact-info i {
  font-size: 16px;
  width: 16px;
}
.program-card .program-tags {
  margin-bottom: 0;
  margin-top: 24px;
}
.program-card .program-description {
  position: relative;
  line-height: 16px;
  height: 48px;
  overflow: hidden;
}
.program-card .program-description:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 75%
  );
}

/***** PROGRAM DETAILS PAGE STYLES *****/
/*** Program Details Header Styles ***/
.program-details-header {
  position: fixed;
  top: 72px; // position directly under app bar
  width: 100%;
  z-index: 2;
  transition: height 300ms linear;
}
@media only screen and (max-width: 1040px) {
  .program-details-header {
    top: 56px; // app bar is smaller for tablet and mobile viewports
  }
}
.program-details-header.smaller {
  height: 80px;
}
.program-details-header-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #2774ae 0%, #1b91ca 100%);
  opacity: 0.9;
  z-index: 2;
}
.program-details-header img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: #fff;
}
.program-details-header-text {
  position: relative;
  z-index: 3;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 300ms linear;
  padding: 0 40px;
}
.program-details-header-text .title {
  font-family: AvenirRoman;
  font-size: 24px;
  margin-left: 16px;
  border-left: 2px solid #fff;
  padding-left: 24px;
  vertical-align: middle;
}
.back-to-programs {
  transition: opacity 300ms linear;
  margin-bottom: 32px;
}
.back-to-programs:hover {
  opacity: 0.5;
  cursor: pointer;
}
.program-details-header.smaller .program-details-header-text.mt-32 {
  margin-top: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.program-details-header.smaller .xxxl-text {
  font-size: 28px;
  margin-right: 12px;
}
.program-details-header.smaller .back-to-programs {
  margin-bottom: 0;
}
.program-details-header.smaller .back-to-programs span {
  display: none;
}
.program-details-header.smaller .horizontal-menu {
  display: none !important;
}
.program-details-header .program-schedule-title {
  display: none;
}
.program-details-header.smaller .program-schedule-title {
  display: inherit;
}
@media only screen and (max-width: 720px) {
  .back-to-programs {
    display: none;
  }
  .program-details-header .xxxl-text {
    font-size: 20px;
    font-family: "AvenirBlack";
  }
  .program-details-header-text .title {
    font-size: 16px;
  }
}
/*** Program Details Page Styles ***/
.program-details-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 176px;
}
@media only screen and (max-width: 720px) {
  .program-details-page {
    display: block;
    margin-top: 80px;
  }
}
/*** Program Details Sidebar Styles ***/
.program-details-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 224px;
  width: 320px;
  min-width: 320px;
  margin-right: 40px;
  transition: top 300ms linear;
}

.program-details-sidebar .program-tabs .link-text-secondary {
  text-transform: uppercase;
  margin-left: -32px;
  padding-left: 16px;
  margin-bottom: 32px;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.75px;
}

.program-details-sidebar .program-tabs .link-text-secondary:last-child {
  margin-bottom: 0;
}

.program-details-sidebar .program-tabs .link-text-secondary.active {
  border-left: 4px solid;
  color: #2774ae;
  padding-left: 12px;
}
.program-tags {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 24px;
}
.program-tags .tag {
  font-size: 11px;
  display: flex;
  justify-content: center;
}
.program-tags .tag:first-child {
  margin-right: 8px;
}
.program-details-sidebar label {
  color: $accent-text;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
}
.program-dates {
  margin-bottom: 40px;
}
.program-contact-info p {
  color: $accent-text-dark;
  display: flex;
  align-items: center;
  line-height: 2;
  margin-bottom: 8px;
}
.program-contact-info i {
  font-size: 18px;
  color: $accent-text-light;
  width: 20px;
  margin-right: 8px;
}
.apply-btn {
  height: 48px;
}
@media only screen and (max-width: 1040px) {
  .program-details-sidebar {
    top: 272px;
  }
}
@media only screen and (max-width: 720px) {
  .program-details-sidebar {
    position: relative;
    width: 100%;
    top: 0;
  }
  .program-contact-info p {
    font-size: 11px;
  }
  .apply-btn {
    height: 40px;
  }
}
/*** Program Details Overview Styles ***/
.program-details-overview {
  width: 100%;
  padding: 40px;
}
.section-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #edecec;
  height: 72px;
  align-items: center;
  cursor: pointer;
}
.section:first-child .section-title {
  height: 44px;
  align-items: flex-start;
}
.section-title h4 {
  font-family: "AvenirHeavy";
  font-size: 22px;
}
.section-title i {
  font-size: 30px;
  transition: opacity 300ms linear;
  cursor: pointer;
}
.jotform-link i {
  transition: opacity 300ms linear;
  cursor: pointer;
}
.section-title i:hover,
.jotform-link i:hover {
  opacity: 0.5;
}
.section-title i.copy-link {
  font-size: 15px;
  margin-left: 16px;
  margin-top: 4px;
}
.section-title i.copy-link {
  display: none;
}
.section-title:hover i.copy-link {
  display: initial;
}
.section-body {
  padding: 24px 0;
  border-bottom: 1px solid #edecec;
  line-height: 2;
}
.section-body strong {
  font-family: AvenirBlack;
}
.section-body em {
  font-style: italic;
}
.section .section-body {
  display: none;
}
.section.open .section-body {
  display: block;
}
.section i.open,
.section.open i.closed {
  display: none;
}
.section.open i.open {
  display: block;
}
.program-details-overview.card .section ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 24px;
}
.program-details-overview.card .section ol {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: 24px;
}
.program-details-overview.card .section ul ul,
.program-details-overview.card .section ol ul {
  list-style-type: circle;
  list-style-position: outside;
  margin-left: 15px;
}
.program-details-overview.card .section ol ol,
.program-details-overview.card .section ul ol {
  list-style-type: lower-latin;
  list-style-position: outside;
  margin-left: 15px;
}
.program-details-overview.card a {
  color: $accent-color;
}
.program-details-overview.card .section-body p {
  margin-bottom: 8px;
}
.program-jotform-modal {
  padding: 16px;
  height: 100vh;
  width: 96vw;
}
@media only screen and (max-width: 720px) {
  .program-details-overview {
    margin-left: 0;
    margin-top: 24px;
    padding: 0;
  }
  .section-title,
  .section-body {
    padding: 16px;
  }
  .section:first-child .section-title {
    height: 48px;
    align-items: center;
  }
  .section-title {
    height: 48px;
  }
  .section-title h4 {
    font-size: 14px;
  }
  .section-subtitle {
    font-size: 12px;
  }
  .section-body {
    font-size: 12px;
  }
  .program-jotform-modal {
    width: 100vw;
  }
}
/*** Program Registration Jotform Modal Shliach Details Styles ***/
.program-jotform-modal .shliach-details {
  padding: 8px;
}
.program-jotform-modal .shliach-details-card {
  margin: 24px 0px;
  padding: 16px;
  border: 1px solid #eeee;
  border-radius: 3px;
}
.program-jotform-modal .shliach-details-card .profile-image {
  height: 72px;
  width: 72px;
  border-radius: 100%;
}
.program-jotform-modal .shliach-details-card .profile-image-sm {
  height: 32px;
  width: 32px;
  border-radius: 10%;
}
.program-jotform-modal .shliach-details-card .profile-image-sm-placeholder {
  height: 32px;
  width: 32px;
}
.program-jotform-modal .shliach-details-card .social-links img {
  height: 16px;
}
.program-jotform-modal .shliach-details-card > div {
  padding: 20px;
}
.program-jotform-modal .shliach-details-card > div:nth-child(2) {
  border-top: 1px solid #eee;
}
.program-jotform-modal .shliach-details-card .chabad-house-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}
.program-jotform-modal .shliach-details-card .chabad-house-details > div {
  padding-right: 8px;
}
.program-jotform-modal
  .shliach-details-card
  .chabad-house-details
  > div:not(:last-child) {
  border-right: 1px solid #eee;
}
.program-jotform-modal
  .shliach-details-card
  .chabad-house-details
  .two-column-grid {
  grid-row-gap: 8px;
  grid-template-columns: 0.25fr 1fr;
}
@media only screen and (max-width: 560px) {
  .program-jotform-modal .shliach-details-card > div {
    padding: 8px;
  }
  .program-jotform-modal .shliach-details-card .chabad-house-details {
    display: block;
  }
  .program-jotform-modal .shliach-details-card .chabad-house-details > div {
    padding-top: 16px;
  }
  .program-jotform-modal
    .shliach-details-card
    .chabad-house-details
    > div:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #eee;
    padding-bottom: 16px;
  }
  .program-jotform-modal .shliach-details-buttons {
    margin: 0px 16px 60px 0px;
  }
  .program-jotform-modal .program-jotform-modal-header {
    margin-top: 60px;
  }
}
